import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

declare var $: any
@Component({
  selector: 'app-list-blog',
  templateUrl: './list-blog.component.html',
  styleUrls: ['./list-blog.component.css']
})
export class ListBlogComponent implements OnInit {

  searchForm: FormGroup;
  bodyListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  contentId: any;
  today = new Date().toISOString().split('T')[0]
  userStatus: any = 'ACTIVE';
  filterForm : FormGroup = new FormGroup({
    status : new FormControl(""),
    fromDate : new FormControl(""),
    toDate : new FormControl(""),
    search : new FormControl("")
  })
  action: any;
  id: any;

  constructor(private router: Router, public commonService: ServiceService) { }

  ngOnInit() {
    this.searchFormValidation();
    this.getEmbellishmentList();
    console.log(this.today)
  }

  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(''),
      status: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }
  searchFormSubmit() {
    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.getEmbellishmentList()
    }
  }
  searchFormReset() {
    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset({
        search: '',
        status: '',
        fromDate: '',
        toDate: ''
      });
      this.getEmbellishmentList()
    }
  }

  selectStatus() {
    this.currentPage = 1
  }
  // -------------------- get category list --------------------- //
  getEmbellishmentList() {
    
    let fromDate = this.searchForm.value.fromdate ? new Date(new Date(this.searchForm.value.fromdate).setHours(0,0,0,0)).getTime() : ''
    let toDate = this.searchForm.value.fromdate ? new Date(new Date(this.searchForm.value.toDate).setHours(23,59,59)).getTime() : ''
    let search = this.searchForm.value.search ? this.searchForm.value.search : ''

    let apiReqUrl: any = `static/admin/static-content/get-Blog-list?page=${this.currentPage - 1}&pageSize=${this.itemPerPage}${fromDate ? '&fromDate='+fromDate :''}${toDate ? '&toDate='+toDate :''}${search ? '&title='+search :''}`
    this.commonService.showSpinner();
    this.commonService.get(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.bodyListArray = res.data.list ? res.data.list : [];
        this.totalItems = res.data.count
        this.commonService.hideSpinner();
      } else {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    },(err)=>{
      this.commonService.hideSpinner();
      if(err.status == 404){
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }
    })
  }

  pagination(event) {
    console.log(event)
    this.currentPage = event;
    this.getEmbellishmentList()
  }

  addBody() {
    this.router.navigate(['/add-blog'])
  }
  // view category
  viewBody(contentId) {
    this.router.navigate(['/view-blog'], { queryParams: { contentId: contentId } })
  }
  // edit category
  editBody(contentId) {
    this.router.navigate(['/edit-blog'], { queryParams: { contentId: contentId } })
  }
  // ------------------------------- delete hospital ----------------------------- //
  deleteCategoryModal(contentId) {
    $('#deleteCategory').modal('show')
    this.contentId = contentId
  }
  deleteCategory() {
    let apiReqUrl: any = "static/admin/static-content/delete-Blog-by-id?blogId="+this.contentId
    this.commonService.showSpinner();
    this.commonService.post(apiReqUrl,{}).subscribe((res: any) => {
      $('#deleteCategory').modal('hide');
      if (res.status == 200) {
        this.getEmbellishmentList()
        this.commonService.toasterSucc(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  blockBlog() {
    let apiReqUrl = `static/admin/static-content/update-Blog-status-by-id?status=${this.action == 'ACTIVE' ? true : false}`
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl,{contentId : this.id}).subscribe((res: any) => {
      $('#active').modal('hide');
      $('#block').modal('hide');
      if (res.status == 200) {
        this.getEmbellishmentList()
        let message = this.action == 'ACTIVE' ?  'Blog activated successfully.' :'Blog disable successfully.'
        this.commonService.toasterSucc(message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  openModal(action, userId) {
    this.id = userId;
    this.action = action;
    if (action == 'BLOCK') {
      $('#block').modal('show')
    }
    else {
      $('#active').modal('show')
    }
  }
  performAction(){
    console.log(this.id, this.action);
    
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { url } from 'inspector';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-trade-management',
  templateUrl: './trade-management.component.html',
  styleUrls: ['./trade-management.component.css']
})
export class TradeManagementComponent implements OnInit {
  fromDate: any = ''
  twoDate: any = ''
  calender: any = { todate: '', formdate: '' }
  minAge: Date;
  tradeStatus: any = ''
  country: any = ''
  paymentType: any = '';
  type: any = '';
  tradeList: any = [];
  itemsPerPage: number = 20;
  currentPage: number = 1
  totalItems: number;
  countryList: any = [];

  constructor(private router: Router, public service: ServiceService) { }

  ngOnInit() {
    var today = new Date();
    var minAge = 0;
    this.minAge = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate());
    this.getTradeList()
    this.getCountryList()
  }

  /** to switch between tabs */
  currTab: any = 'HOT';
  selectTab(tab) {
    this.currTab = tab;
    if (this.currTab == 'HOT') {
      this.getTradeList();
    } else {
    
      this.getcoinPairlist()
    }
  }
  coinpairlistarray: any
  getcoinPairlist() {
    this.service
      .post("wallet/coin/get-coinPair-list", "")
      .subscribe((res: any) => {
        this.coinpairlistarray = res.data;
        this.tradeStatus = `${res.data[0].baseCoin}/${res.data[0].executableCoin}`
        this.toArray[0] = res.data[0].baseCoin
        this.toArray[1] =res.data[0].executableCoin
        this.exchangeTrade();
      });
  }
  base: any
  exe: any
  toArray: any = []
  coinFilter(event) {
    console.log(event.target.value);
    this.base = event.target.value
    this.toArray = this.base.split("/");
    console.log(this.toArray);

  }

  formdate() {
    this.fromDate = new Date(this.calender.formdate)
    this.fromDate = this.fromDate.getTime()
  }
  todate() {
    this.twoDate = new Date(this.calender.todate)
    this.twoDate = this.twoDate.getTime()
  }

  // get trade list 
  getTradeList() {
    let url = `p2p-exchange/admin/search-and-filters-trade-list?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}${(this.tradeStatus ? ('&tradeStatus=' + this.tradeStatus) : '')
      + (this.country ? ('&country=' + this.country) : '') + (this.paymentType ? ('&paymentType=' + this.paymentType) : '') + (this.type ? ('&type=' + this.type) : '') +
      ((this.fromDate) ? ('&fromDate=' + this.fromDate) : '') + (this.twoDate ? ('&toDate=' + this.twoDate) : '')}`
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.status == 200) {
        this.tradeList = res.data.list
        this.totalItems = res.data.totalCount;
      } else {
        this.tradeList = []
        this.totalItems = 0
      }
    }, err => {
      this.service.hideSpinner();
      this.tradeList = []
      this.totalItems = 0
    })
  }

  exchangeList: any
  allItems: any
  history: any = []
  exchangeTrade() {
    let url = "order-service/get-trade-history-allUser"
    const data = {
      "baseCoin": this.toArray[0],
      "exeCoin": this.toArray[1],
      // "fromDate": 0,
      "side": this.type,
      // "toDate": 0,
      // "transactionId": 0,
      // "userId": 0
    }
    this.service.showSpinner();
    this.service.post(url, this.service.removeEmptyKey(data)).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.status == 200) {
        // res.data.forEach((element) => {
          // res.data.history.forEach((ele) => {
          //   this.history.push(ele)
          // });
        // })
        this.history = res.data.history
        console.log(this.history);

        console.log(this.exchangeList);

        this.allItems = res.data.totalCount;
      } else {
        this.exchangeList = []
        this.allItems = 0
      }
    }, err => {
      this.service.hideSpinner();
      this.exchangeList = []
      this.allItems = 0
    })
  }

  // search 
  search() {
    if (this.tradeStatus || this.country || this.paymentType || this.type || (this.twoDate || this.fromDate)) {
      this.getTradeList()
    } else {
    }
  }

  // reset search 
  reset() {
    if (this.tradeStatus || this.country || this.paymentType || this.type || (this.twoDate || this.fromDate)) {
      this.tradeStatus = ''
      this.country = ''
      this.paymentType = '';
      this.type = '';
      this.calender = { todate: '', formdate: '' }
      this.twoDate = ''
      this.fromDate = ''
      this.getTradeList()
    }
  }

  // pagination
  pagination(page) {
    this.currentPage = page;
    this.getTradeList()
  }

  pagination1(page) {
    this.currentPage = page;
    // this.exchangeTrade()
  }

  // get country list
  getCountryList() {
    this.service.get('account/get-country-list').subscribe((res) => {
      if (res['status'] == 200) {
        this.countryList = res['data']
      }
    })
  }

  // nevigate to trade details
  navigate(tradeId) {
    this.router.navigate(['/trade-details', tradeId])
  }

  disableSearchbtn() {

    if (this.tradeStatus || this.type) {
      return false
    }
    else {
      return true
    }
  }

}

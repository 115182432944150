import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

declare var $: any
@Component({
  selector: 'app-view-fund-withdraw',
  templateUrl: './view-fund-withdraw.component.html',
  styleUrls: ['./view-fund-withdraw.component.css']
})
export class ViewFundWithdrawComponent implements OnInit {
  bank: any;
  skirtListArray: any;
  colorList: any = [];
  editData: any
  userId: any
  complete: any
  pending: any
  reject: any
  utrNo: any
  fiatStatus: any
  amount: any;
  currentPage:1
  itemsPerPage:1000000
  constructor(private activatedroute: ActivatedRoute, public commonService: ServiceService) { }

  ngOnInit() {
    this.editData = JSON.parse(sessionStorage.getItem('dataNewWithdarwa'))
    console.log(this.editData);
  }
  searchByWithdrawList() {
    // let status = this.withdrawForm.value.status
    let url = `wallet/admin/withdraw-Inr-List-admin?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}`
    this.commonService.showSpinner();
    this.commonService.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          // this.widtralDataInr = res.data;
        }
        // this.lengthTotal = res['data'].totalCount
        //  this.totalRecords = res.data.totalCount;
        this.commonService.hideSpinner();
      },
      (err: any) => {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(err.error.message);
      }
    );
  }

  // approve() {
  //   let data = {}
  //   this.commonService.post(`wallet/admin/Deposit-Inr-Status?fiatStatus=${this.complete}&utrNo=${this.utrNo}`, data).subscribe((res) => {
  //     if (res['status'] == 200) {
  //       $('#approveModal').modal('hide')
  //     }
  //   })
  // }
  approve() {
    let data = {
      fiatStatus :"COMPLETED",
      id: this.editData.txnId,
      transactionType : 'WITHDRAW'
    }

    this.commonService.putApi(`wallet/approve-reject-fiat-request`, data).subscribe((res) => {
      if (res['status'] == 200) {
        this.editData.status = 'CONFIRM'
        $('#approveModal').modal('hide')
      }
    })
  }

  approveKyc(status) {
    this.complete = status
    $('#approveModal').modal('show')
  }

  openModal(status) {
    this.pending = status
    $('#reject').modal('show')
  }
  rejectKyc(status){
    this.complete = status
    $('#reject').modal('show')
  
  }
  rejectionReason : any

  withdrawInrFundReject() {
    this.commonService.showSpinner()
    let data = {
      fiatStatus:`REJECTED` ,
      id : this.editData.txnId,
      transactionType : `WITHDRAW`,
      rejectionReason : this.rejectionReason
    }
    let url = `wallet/approve-reject-fiat-request`
    this.commonService.putApi(url, data).subscribe((res) => {
      if (res['status'] == 200) {
        this.editData.status = 'REJECTED'
        $('#reject').modal('hide')
        this.commonService.hideSpinner()
      }
    })
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServiceService } from '../service.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrls: ['./add-admin.component.css']
})
export class AddAdminComponent implements OnInit {
  addForm: FormGroup;
  ipAddress: any;
  newArr: any = [];


  constructor(public service: ServiceService, public router: Router, private http: HttpClient) {
    this.http.get<{ ip: any }>('https://geolocation-db.com/json/')
      .subscribe(data => {
        this.ipAddress = data['IPv4']
      })
  }

  ngOnInit() {
    this.addForm = new FormGroup({
      'firstName': new FormControl('', [Validators.pattern(/^[a-z,.'-]+$/i), Validators.required]),
      'lastName': new FormControl('', [Validators.pattern(/^[a-z,.'-]+$/i), Validators.required]),
      'email': new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      'role': new FormControl('', Validators.required),
      'gender': new FormControl('', Validators.required),
      'phoneNumber': new FormControl('', [Validators.required, Validators.pattern(/^[1-9][0-9]{8,13}$/), Validators.maxLength(18)])
    })
  }

  addStaff() {

    let data = {
      "address": "string",
      "city": "string",
      "country": "INDIA",
      "countryCode": "+91",
      "dob": "string",
      "email": this.addForm.value.email,
      "firstName": this.addForm.value.firstName,
      "gender": this.addForm.value.gender,
      "imageUrl": "string",
      "ipAddress": this.ipAddress,
      "lastName": this.addForm.value.lastName,
      "location": "string",
      "phoneNo": this.addForm.value.phoneNumber,
      "previlage": this.newArr,
      "roleStatus": this.addForm.value.role,
      "state": "string",
      "webUrl": `${this.service.websiteURL}reset-password`,
      "userIdToUpdate": 12
    }
    this.service.showSpinner()
    this.service.postApi('account/admin/user-management/create-sub-admin', data).subscribe((res) => {
      this.service.hideSpinner()
      if (res.status == 200) {
        this.service.toasterSucc(res.message)
        this.router.navigate(['/admin-management'])
      }
    }, err => {
      this.service.hideSpinner()
      if (err.status == 400) {
        this.service.toasterErr(err.error.message)
      } else {
        this.service.toasterErr(err.error.message)
      }
    })
  }

  /** Function for checkbox click */
  checkboxClick(value, checked) {
    if (checked == true) {
      this.newArr.push(value);
    } else {
      let index = this.newArr.findIndex(x => x == value)
      this.newArr.splice(index, 1)
    }
  }

  cancel() {
    this.router.navigate(['/admin-management'])
  }

}

import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { isNgTemplate } from '@angular/compiler';


@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {
  userDetail: any;
  profile = ''
  pageNumber: number = 1;
  pageSize: number = 10
  userID: any;

  userId: any = [];
  currTab: any = 'general';
  currTabb: any = 'BTC';

  loginDetails: any = [];
  email: any;
  tradingForm: FormGroup;
  tradingList: any = [];
  tradingListlength: any;
  viewTrading: boolean = false;
  viewTradingg: boolean = false;
  viewEth: boolean = false;
  viewXrp: boolean = false;
  viewLtc: boolean = false;
  walletDetailsList: any = [];
  walletDetailsListLength: any;
  coinList: any = [];
  coinlist: any;
  coinListArr: any = [];
  tradingCurrency: any = ''
  tradingStatus: any = ''
  fromDate: any = ''
  twoDate: any = ''
  calender: any = { todate: this.twoDate, fromdate: this.fromDate }
  minAge: Date;

  totalItems: number;
  tradestatus: any;
  y: any;
  transferForm: FormGroup
  copyToClipboard(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  constructor(private router: Router, public service: ServiceService, private route: ActivatedRoute) { }

  ngOnInit() {

    this.y = this.tradingCurrency
    var today = new Date();
    var minAge = 0;
    this.minAge = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate());
    this.fromdate()

    // this.staffForm = new FormGroup({
    //   status: new FormControl(''),
    //   role: new FormControl(''),
    //   fromDate: new FormControl(''),
    //   toDate: new FormControl(''),
    //   search: new FormControl('')
    // });
    this.tradingForm = new FormGroup({
      // tradingstatus: new FormControl(''),
      // tradingCurrency: new FormControl(''),
      tradingfromDate: new FormControl(''),
      tradingtoDate: new FormControl(''),
      tradingStatus: new FormControl(''),
    })
    this.searchFormValidation()
    let obj = this.route.queryParams.subscribe(params => {

      this.userId = (params['userId']); // (+) converts string 'id' to a number
      this.email = (params['email']);

      localStorage.setItem('userId', this.userId)
    });
    this.viewDetail();
    this.getCoinWalletList();
    this.getTradingHistory()
    // this.reverseArr();
    this.getNominee()

  }

  searchFormValidation() {
    this.transferForm = new FormGroup({
      firstname: new FormControl(''),
      lastname: new FormControl(''),
      nomineeStatus: new FormControl(''),
      sharePercentage: new FormControl('')
    })
  }

  // nominee filter start
  ekFunctionZindgiKa() {

    let firstname = this.transferForm.value.firstname;
    let lastname = this.transferForm.value.lastname
    let nomineeStatus = this.transferForm.value.nomineeStatus
    let sharePercentage = this.transferForm.value.sharePercentage
    // let url = `wallet/admin/get-user-transaction-history-admin?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}${this.transferForm.value.fromDate ? "&fromDate=" + fromDate : ""}${this.transferForm.value.toDate ? "&toDate=" + toDate : ""}${this.transferForm.value.coinNameSe ? "&coinName=" + coinNameSe : ""}${this.transferForm.value.amount ? "&amount=" + amount : ""}${this.transferForm.value.type ? "&txnType=" + type : ""}${this.transferForm.value.searchTextTxnHash ? "&txnHash=" + searchTextTxnHash : ""}${this.transferForm.value.status ? "&status=" + status : ""}${this.transferForm.value.userEmail ? "&userEmail=" + userEmail : ""}${this.transferForm.value.userName ? "&userName=" + userName : ""}`
    //  let zindgi  =  `wallet/admin/get-user-transaction-history-admin?amount=${this.transferForm.value.amount}&coinName=${this.transferForm.value.coinNameSe}&fromDate=${Math.round(new Date(this.transferForm.value.fromDate).getTime())}&page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}&status=${this.transferForm.value.status}&toDate=${Math.round(new Date(this.transferForm.value.toDate).getTime())}&txnHash=${this.transferForm.value.searchTextTxnHash}&txnType=${this.transferForm.value.type}`
    let url = `account/admin/get-details-by-userId-NEW?userId=${this.userId}${this.transferForm.value.firstname ? "&firstname=" + firstname : ""}${this.transferForm.value.lastname ? "&lastname=" + lastname : ""}${this.transferForm.value.nomineeStatus ? "&nomineeStatus=" + nomineeStatus : ""}${this.transferForm.value.sharePercentage ? "&sharePercentage=" + sharePercentage : ""}`
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.nomineeListArray = res.data ? res.data : '';
          this.totalRecords = res['data']
          this.service.hideSpinner();
        }
        // this.lengthTotal = res['data'].totalCount
        //  this.totalRecords = res.data.totalCount;
        else {
          this.service.hideSpinner();
          this.service.toasterErr(res['message'])
        }
      },
      (err: any) => {
        this.service.hideSpinner();
        // this.service.toasterErr(err.error.message);
      }
    );
  }
  userList: any
  tradeList() {
    let url = `order-service/get-order-history`;
    this.service.showSpinner();
    this.service.post(url, {}).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.userList = res['data']['list'];
          this.totalItems = res.data.totalCount;
        }
        this.service.hideSpinner();
      },
      (err: any) => {
        this.service.hideSpinner();
        // this.service.toasterErr(err.error.message);
      }
    );
  }


  resetNominee() {
    if (
      this.transferForm.value.firstname || this.transferForm.value.lastname || this.transferForm.value.nomineeStatus || this.transferForm.value.sharePercentage

    ) {
      this.transferForm.reset({
        firstname: "",
        lastname: "",
        nomineeStatus: "",
        sharePercentage: ""
      });
      this.getNominee();
    }
  }
  // nominee filter end




  fromdate() {
    this.fromDate = new Date(this.calender.fromdate)
    this.fromDate = this.fromDate.getTime()
  }
  todate() {
    this.twoDate = new Date(this.calender.todate)
    this.twoDate = this.twoDate.getTime()
  }

  selectTab(tab) {
    this.currTab = tab;
    if (this.currTab == 'trading') {
      this.tradeList();
    }
  }
  // selectTabb(tab) {
  //   this.currTabb = tab;
  //   if (this.currTabb == 'ETH') {
  //     this.walletDetailss('ETH');
  //   }
  //   else if (this.currTabb == 'XRP') {
  //     this.walletDetailss('XRP');
  //   }
  //   else if (this.currTabb == 'LTC') {
  //     this.walletDetailss('LTC');
  //   }
  //   else if (this.currTabb == 'BTC') {
  //     this.walletDetailss('BTC');
  //   }
  // }

  selectTabb(coin) {
    this.walletDetailss(coin.target.value)
  }

  performAction() { }
  walletDetails() {
    this.service.showSpinner();
    this.service.get('wallet/admin/get-user-transaction-history-admin?coinName=' + (this.currTabb) + '&page=' + (0) + '&pageSize=' + (10) + '&userId=' + (this.userId)).subscribe((res: any) => {
      this.service.hideSpinner()
      if (res.status == 200) {
        this.walletDetailsList = res.data.resultlist;
        this.walletDetailsListLength = res.data.totalCount;
      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
    })
  }

  getProfile() {
    this.service.showSpinner();
    this.service.get('account/get-user?email=' + (this.email)).subscribe((res) => {
      this.service.hideSpinner();
      this.loginDetails = res['data']
    })
  }
  viewCoinDetails(transactionId) {
    this.service.showSpinner();
    this.getCoinDetailsByuserId(transactionId);
    this.viewTradingg = true;
    setTimeout(() => {
      this.service.hideSpinner();
    }, 2000);
  }

  viewCoinBTCDetails(transactionId) {
    this.service.showSpinner();
    this.getCoinDetailsByuserId(transactionId);
    this.viewEth = true;
    setTimeout(() => {
      this.service.hideSpinner();
    }, 2000);
  }

  viewXrpCoinDetails(transactionId) {
    this.service.showSpinner();
    this.getCoinDetailsByuserId(transactionId);
    this.viewXrp = true;
    setTimeout(() => {
      this.service.hideSpinner();
    }, 2000);
  }

  viewLtcCoinDetails(transactionId) {
    this.service.showSpinner();
    this.getCoinDetailsByuserId(transactionId);
    this.viewLtc = true;
    setTimeout(() => {
      this.service.hideSpinner();
    }, 2000);
  }

  nomineeListArray: any = []
  totalRecords: any
  // get nominee by id
  getNominee() {
    var url = 'account/admin/get-details-by-userId-NEW?userId=' + this.userId;

    this.service.showSpinner();
    // this.service.getCandyPixelForms(`account/get-details-by-userId`).subscribe(
    this.service.get(url).subscribe(
      (res) => {
        if (res["status"] == 200) {
          this.nomineeListArray = res["data"];
          this.totalRecords = res['data']
          this.service.hideSpinner();

        } else {
          this.service.hideSpinner();
          // this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        // this.service.toasterErr(err['message']);
      }
    );
  }
  viewNominee(nomineeId) {
    // alert(nomineeId)
    this.router.navigate(['/nominee-view'], { queryParams: { nomineeId: nomineeId } })
  }

  // 'wallet/coin/get-coin-list'

  getCoinWalletList() {
    this.service.showSpinner();
    this.service.get('wallet/coin/get-coin-list').subscribe((res: any) => {
      if (res.status == 200) {
        this.coinlist = res.data;

        this.coinlist.forEach(element => {
          this.coinListArr.push(element.coinShortName);
          let removeCoin = ["BCH", "OMG", "XLM", "DASH", "LTC", "INR"];
          this.coinList = this.coinList.filter((ele) => {
            return !removeCoin.includes(ele.coinShortName);
          });
        });
        this.service.hideSpinner();

      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
    })
  }

  getCoinDetailsByuserId(transactionId) {
    // http://182.72.203.244:3062/wallet/admin/transaction-history/get-transaction-details?txnId=1
    this.service.get('wallet/admin/transaction-history/get-transaction-details?txnId=' + (transactionId)).subscribe((res: any) => {
      if (res.status == 200) {
        this.coinList = res.data;
        // this.tradingListlength = res.data.length;
      }
    })
  }

  viewTradingDetails(userId, transactionId) {
    this.service.showSpinner();
    this.getTradingHistoryByuserId(userId, transactionId);
    this.viewTrading = true;
    setTimeout(() => {
      this.service.hideSpinner();
    }, 2000)
  }
  getTradingHistoryByuserId(userId, transactionId) {
    let data = {
      "userId": this.userId,
      "transactionId": transactionId
    }
    this.service.post('order-service/get-trade-history', data).subscribe((res: any) => {
      if (res.status == 200) {
        this.tradingList = res.data[0];
        // this.tradingListlength = res.data.length;
      }
    })
  }
  backk() {
    this.viewTrading = false;
    this.viewDetail();
    this.getProfile();
    this.getTradingHistory();
    this.walletDetails();
  }
  backkk() {
    this.viewTradingg = false;
    this.viewDetail();
    this.getProfile();
    this.getTradingHistory();
    this.walletDetails();
  }
  backfromEth() {
    this.viewEth = false;
    // this.viewXrp = false;
    // this.viewLtc = false;
    this.viewDetail();
    this.getProfile();
    this.getTradingHistory();
    this.walletDetails();
  }

  backfromXrp() {
    this.viewXrp = false;
    // this.viewLtc = false;
    this.viewDetail();
    this.getProfile();
    this.getTradingHistory();
    this.walletDetails();
  }

  backfromLtc() {
    this.viewLtc = false;
    this.viewDetail();
    this.getProfile();
    this.getTradingHistory();
    this.walletDetails();
  }
  pagination(page) {
    this.pageNumber = page;
  }


  viewDetail() {
    var url = 'account/admin/user-management/user-details?userId=' + this.userId;
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.getProfile();
        this.getTradingHistory();
        this.walletDetails();
        this.userDetail = res['data']
        this.profile = this.userDetail.imageUrl
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  back() {
    this.router.navigate(['user-management/'])
  }

  /** to switch between tabs */

  walletDetailss(data) {
    this.service.showSpinner();
    this.service.get('wallet/admin/get-user-transaction-history-admin?coinName=' + (data) + '&page=' + (0) + '&pageSize=' + (10) + '&userId=' + (this.userId)).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.status == 200) {
        this.walletDetailsList = res.data.resultlist;
        this.walletDetailsListLength = res.data.totalCount;
      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
    })
  }
  //order-service/get-trade-historypage=${this.currentPage - 1}&pageSize=${this.itemsPerPage}${(this.userId2 ? ('&userId2=' + this.userId2) : '') + (this.userName ? ('&userName=' + this.userName) : '') +
  // (this.fromDate ? ('&fromDate=' + this.fromDate) : '') + (this.twoDate ? ('&toDate=' + this.twoDate) : '') + (this.orderStatus ? ('&orderStatus=' + this.orderStatus) : '') + (this.country ? ('&country=' + this.country) : '') +
  // (this.paymentType ? ('&paymentType=' + this.paymentType) : '') + (this.orderType ? ('&orderType=' + this.orderType) : '')}'

  getTradingHistory() {
    let data = {
      /*      "baseCoin": "BTC",
           "exeCoin": "ETH",
           "side": "SELL", */
      "userId": this.userId,
      "baseCoin": this.y,
      "status": this.tradestatus
    }
    this.service.post("order-service/get-trade-history", {}).subscribe((res: any) => {

      if (res.status == 200) {
        this.tradingList = res.data;
        this.tradingListlength = res.data.length;
      }
    })
  }

  reverseArr() {
    let arr = [1, 2, 3, 4, 5, 6, 7];
    let newArr = [];
    let i;
    for (arr.length - 1; i >= 0; i--) {
      newArr.push(arr[i])
    }

  }

  searchTrading() {
    this.service.showSpinner();
    let searchAndFilterDto = {

      "baseCoin": this.tradingCurrency ? this.tradingCurrency : null,
      // "exeCoin": "string",
      "fromDate": this.fromDate ? this.fromDate : null,
      "side": this.tradingStatus ? this.tradingStatus : null,
      "toDate": this.twoDate ? this.twoDate : null,
      //  "transactionId": 0,
      "userId": this.userId
    }
    this.service.post("order-service/get-trade-history", searchAndFilterDto).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.status == 200) {
        this.tradingList = res.data;
        this.tradingListlength = res.data.length;
        this.service.toasterSucc('Success')


      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
    })



  }

  reset() {
    this.getTradingHistory();
    this.tradingList = [];
    this.tradingListlength = 0
    this.pageNumber = 1;

    if (this.fromDate || this.twoDate || this.tradingStatus || this.tradingCurrency) {

      this.fromDate = ''
      this.twoDate = ''
      this.calender = { todate: '', fromdate: '' }
      this.tradingCurrency = ''
      this.tradingStatus = ''

      /*   this.tradingForm.value.tradingstatus='' */
    }
    else {

    }


  }

  exportAsXLSX() {
    let dataArr = [];
    this.tradingList.forEach((element, ind) => {

      dataArr.push({
        "S No": ind + 1,
        "Transaction ID": element.transactionId ? element.transactionId : 'N/A',
        "Transaction Type": element.orderSide ? element.orderSide : 'N/A',
        "Amount": element.quantity ? element.quantity : 'N/A',
        "Base Coin": element.baseCoin ? element.baseCoin : 'N/A',
        "Executable Coin": element.exeCoin ? element.exeCoin : 'N/A',
        "Date and Time": element.lastExecutionTime ? element.lastExecutionTime.slice(0, 10) : 'N/A',
      })
    })

    this.service.exportAsExcelFile(dataArr, 'Trading list');
  }

  disableSearchbtn() {

    if (this.transferForm.value.firstname || this.transferForm.value.lastname || this.transferForm.value.nomineeStatus || this.transferForm.value.sharePercentage ||
      this.fromDate || this.twoDate || this.tradingStatus || this.tradingCurrency || this.fromDate || this.twoDate || this.tradingStatus || this.tradingCurrency
      || this.transferForm.value.firstname || this.transferForm.value.lastname || this.transferForm.value.nomineeStatus || this.transferForm.value.sharePercentage
    ) {
      return false
    }
    else {
      return true
    }

  }
}

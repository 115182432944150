import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";

declare var $: any;
@Component({
  selector: "app-list-announcement",
  templateUrl: "./list-announcement.component.html",
  styleUrls: ["./list-announcement.component.css"],
})
export class ListAnnouncementComponent implements OnInit {
  searchForm: FormGroup;
  bodyListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  announcementId: any;
  userForm: FormGroup;
  pageNumber = 1
  // today = new Date().toISOString().split('T')[0]
  userStatus: any = "ACTIVE";
  today: any = new Date().toISOString().slice(0, 16);

  constructor(private router: Router, public commonService: ServiceService) {}

  ngOnInit() {
    this.userForm = new FormGroup({
      fromDate: new FormControl(""),
      toDate: new FormControl(""),
      title: new FormControl(""),
    });
    this.searchFormValidation();
    this.getEmbellishmentList();
    console.log(this.today);
  }
  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(""),
      status: new FormControl(""),
      fromDate: new FormControl(""),
      toDate: new FormControl(""),
    });
  }
  reset() {
    if (this.userForm.value.fromDate || this.userForm.value.toDate || this.userForm.value.title) {
      this.userForm.reset({
        fromDate: "",
        toDate: "",
        title : ""
      });
      this.getEmbellishmentList();
    }
  }
  searchFormSubmit() {
    if (
      this.userForm.value.title ||
      this.userForm.value.status ||
      this.userForm.value.fromDate ||
      this.userForm.value.toDate
    ) {
      this.getEmbellishmentList();
    }
  }
  searchFormReset() {
    if (
      this.userForm.value.title ||
      this.userForm.value.status ||
      this.userForm.value.fromDate ||
      this.userForm.value.toDate
    ) {
      this.userForm.reset({
        title: "",
        status: "",
        fromDate: "",
        toDate: "",
      });
      this.getEmbellishmentList();
    }
  }

  selectStatus() {
    this.currentPage = 1;
  }
  // -------------------- get category list --------------------- //
  getEmbellishmentList() {
    let fromDate = Date.parse(this.userForm.value.fromDate);
    let toDate = Date.parse(this.userForm.value.toDate);
    let apiReqUrl: any = `static/get-announcement-list?page=${
      this.currentPage - 1
    }&pageSize=${this.itemPerPage}${
      this.userForm.value.fromDate ? "&fromDate=" + fromDate : ""
    }${this.userForm.value.toDate ? "&toDate=" + toDate : ""}${this.userForm.value.title ? '&title='+this.userForm.value.title : ''}`;
    this.commonService.showSpinner();
    this.commonService.get(apiReqUrl).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.bodyListArray = res.data ? res.data : "";
          this.totalItems = res.data.count;
          this.commonService.hideSpinner();
        } else {
          this.bodyListArray = [];
          this.totalItems = 0;
          this.commonService.hideSpinner();
          this.commonService.toasterErr(res.message);
        }
      },
      (err) => {
        this.commonService.hideSpinner();
        if (err.status == 404) {
          this.bodyListArray = [];
          this.totalItems = 0;
        }
      }
    );
  }

  pagination(event) {
    this.currentPage = event;
    this.getEmbellishmentList();
  }

  addBody() {
    this.router.navigate(["/add-announcement"]);
  }
  // view category
  viewBody(announcementId) {
    this.router.navigate(["/view-announcement"], {
      queryParams: { announcementId: announcementId },
    });
  }

  // edit category
  editBody(announcementId) {
    this.router.navigate(["/edit-announcement"], {
      queryParams: { announcementId: announcementId },
    });
  }

  // ------------------------------- delete hospital ----------------------------- //
  deleteCategoryModal(announcementId) {
    $("#deleteCategory").modal("show");
    this.announcementId = announcementId;
  }
  deleteCategory() {
    let apiReqUrl: any =
      "static/delete-announcement?announcementId=" + this.announcementId;
    this.commonService.showSpinner();
    this.commonService.delete(apiReqUrl).subscribe((res: any) => {
      $("#deleteCategory").modal("hide");
      if (res.status == 200) {
        this.getEmbellishmentList();
        this.commonService.toasterSucc(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message);
      }
    });
  }

  // disablesearchbtn() {
  //   if (this.searchForm.value.fromDate || this.searchForm.value.toDate) {
  //     return false
  //   } else {
  //     return true
  //   }
  // }
}

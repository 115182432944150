import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ServiceService } from '../service.service';

declare var $: any


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginData: any;
  Obj: any;
  ipAddress: any;
  view: boolean = false;
  browser: any
  dataLocation:any
  currYear = new Date().getFullYear()
  constructor(
    private router: Router,private fb: FormBuilder,
    private http: HttpClient,
    public service: ServiceService,

  ) { }

  ngOnInit() {
    this.checkInput();
    this.getIP();
    this.myFunction()
  }
  // get IP Address
  getIP() {
    this.service.getIPAddress().subscribe((res: any) => {
      this.dataLocation = res
      this.ipAddress = res['IPv4']

    });
  }

  checkInput() {
    let remData = JSON.parse(localStorage.getItem('rememberMe')) ? JSON.parse(localStorage.getItem('rememberMe')) : '';
    let pass = ''
    if (remData != '') {
      pass = window.atob(remData.password)
    }

    this.loginForm = this.fb.group({
      email: new FormControl(remData.email, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,9}|[0-9]{1,3})(\]?)$/i)),
      password: [pass, [Validators.required]],
      rememberMe: new FormControl(remData ? true : false)

    })
  }
  twoFAStatus: any
  smsToken: any
  onLogin() {
    let location = this.dataLocation ? this.dataLocation.city  + ' ' + this.dataLocation.state  + ' ' + this.dataLocation.country_name : 'NA NA NA'
    
    
    let apiReqData = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
      // userAgent: "ADMIN" && "SUBADMIN",
      userAgent: this.browser,
      location: location,
      ipAddress: this.ipAddress || 'NA',
      deviceType: "WEB",
      // "ipAddress": "",
    }
    this.service.showSpinner();
    this.service.post("auth", apiReqData).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res['status'] == '200') {
        if (res.data.Role == 'ADMIN' || res.data.Role == 'SUBADMIN') {
          // window.location.reload()

          this.twoFAStatus = res["data"]["TwoFa"];

          if (this.loginForm.value.rememberMe == true) {
            let remData = {
              "email": this.loginForm.value.email,
              "password": window.btoa(this.loginForm.value.password)
            }
            localStorage.setItem('rememberMe', JSON.stringify(remData))
          }
          else{
            localStorage.removeItem('rememberMe')
          }
          localStorage.setItem('Auth', res['data']['token']);

          /* new code */
          this.twoFAStatus = res['data']['TwoFa'];
          if (res['data']['TwoFa'] == 'NONE') {
            localStorage.setItem('Auth', res['data']['token']);
            // $('#suggest').modal({ backdrop: 'static' });
            this.router.navigate(['/dashboard'])
            window.location.reload()
          }
          else if (res['data']['TwoFa'] == 'GOOGLE') {
            localStorage.setItem('Auth', res['data']['token']);
            $('#googleAuth').modal({ backdrop: 'static' });
          }
          else if (res['data']['TwoFa'] == 'SMS') {
            localStorage.setItem('Auth', res['data']['token']);
            this.smsToken = res['data']['token']

            $('#smsAuth').modal({ backdrop: 'static' });
            this.suggesstionFunc('sms')
          }
          else if (res['data']['TwoFa'] == 'EMAIL') {
            localStorage.setItem('Auth', res['data']['token']);
            $('#emailAuth').modal({ backdrop: 'static' });
            this.suggesstionFunc('email')
          }
          else if (res['data']['TwoFa'] == 'SKIP') {
            this.service.changeLoginSub('login');
            // localStorage.setItem('Auth', res['data']['token']);
            // this.service.toasterSucc('Login.toasterSuccfully');
            localStorage.setItem("Auth", res["data"]["token"]);
            this.router.navigate(['dashboard']);
            window.location.reload()
          }
          /* new code end */
          // this.service.changeLoginSub('login');
          // localStorage.setItem('Auth', res['data']['token']);
          // window.location.reload()

          // this.router.navigate(['dashboard']);
        } else if (res.data.Role == 'USER') {
          this.service.hideSpinner()
          this.service.toasterErr('Admin detail not found.')
        }
      }
    },
      (err: any) => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          this.service.toasterErr('Please Enter Correct Credential');
        } else {
          this.service.toasterErr('Something went wrong.');
        }
      })
  }

  myFunction() {
    console.log(navigator.userAgent.indexOf("Safari"));
    console.log(navigator.userAgent.indexOf("Safari"));

    if (
      (navigator.userAgent.indexOf("Opera") ||
        navigator.userAgent.indexOf("OPR")) != -1
    ) {
      this.browser = "Opera";
      //alert('Opera');
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      this.browser = "Chrome";
      //alert('Chrome');
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      this.browser = "Safari";
      //alert('Safari');
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      this.browser = "Firefox";
      //alert('Firefox');
    } else if (navigator.userAgent.indexOf("MSIE") != -1) {
      //IF IE > 10
      this.browser = "IE";
      //alert('IE');
    } else {
      this.browser = "Unknown";
      //alert('unknown');
    }

  }
  viewPassword(type) {
    if (type == 'lock') {
      this.view = true
    } else (
      this.view = false
    )
  }
  /* New Code
  -=-=-=-=-=-=-=-=--==-=-=-=-=-=-=-=-=
  */
  googleAuth: any
  secretKey: any
  smsAuth: any
  emailAuth: any
  qrCode: any
  // 2FA Suggestion Modal Functionality
  suggesstionFunc(action) {
    if (action == "Skip") {
      this.service.showSpinner();
      this.service.get("account/skip-twoFa").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.service.toasterSucc("Login Successfully");
            $("#suggest").modal("hide");
            this.service.changeLoginSub("login");
            let credential = localStorage.getItem("Auth");
            localStorage.removeItem("Auth");
            localStorage.setItem("Auth", credential);
            this.router.navigate(["/dashboard"]);
          } else {
            this.service.toasterErr(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    } else if (action == "Google") {
      if (this.twoFAStatus == "GOOGLE") {
        $("#suggest").modal("hide");
        $("#googleAuth").modal("show");
      } else {
        this.service.showSpinner();
        this.service.get("account/google-auth").subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              localStorage.removeItem('Auth')
              localStorage.setItem('Auth', res['data'])
              this.qrCode = res["data"]["qrCode"];
              this.secretKey = res["data"]["secretKey"];
              $("#suggest").modal("hide");
              $("#googleAuth").modal("show");
            }
          },
          (err) => {
            this.service.hideSpinner();
          }
        );
      }
    } else if (action == "sms") {
      if (this.twoFAStatus == "SMS") {
        this.service.showSpinner();
        this.service.get("auth/send-sms-code").subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              localStorage.removeItem('Auth')
              localStorage.setItem('Auth', res['data'])
              $("#suggest").modal("hide");
              $("#smsAuth").modal("show");
              this.service.toasterSucc(res["message"]);
            } else {
              this.service.toasterErr(res["message"]);
            }
          },
          (err) => {
            this.service.hideSpinner();
            if (err["status"] == "500") {
              this.service.toasterErr("Mobile number is not verified");
            } else {
              this.service.toasterErr(err["message"]);
            }
          }
        );
      } else {
        this.service.showSpinner();
        this.service.get("account/send-sms-code").subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              localStorage.removeItem('Auth')
              localStorage.setItem('Auth', res['data'])
              $("#suggest").modal("hide");
              $("#smsAuth").modal("show");
              this.service.toasterSucc(res["message"]);
            } else {
              this.service.toasterErr(res["message"]);
            }
          },
          (err) => {
            this.service.hideSpinner();
            if (err["status"] == "500") {
              this.service.toasterErr("Mobile number is not verified");
            } else {
              this.service.toasterErr(err["message"]);
            }
          }
        );
      }
    }
    // email send code 2fa
    // else if (action == 'email') {
    //   if (this.twoFAStatus == 'EMAIL') {
    //     $('#suggest').modal('hide');
    //     $('#emailAuth').modal('show');
    //   } else {
    //     var url = "account/forget-password-mobile-app?email="+this.loginForm.value.email
    //     this.service.showSpinner();
    //     this.service.get(url).subscribe(res => {
    //       this.service.hideSpinner();
    //       if (res['status'] == 200) {
    //         // this.qrCode = res['data']['qrCode'];
    //         // this.secretKey = res['data']['secretKey'];
    //         $('#suggest').modal('hide');
    //         $('#emailAuth').modal('show');
    //       }
    //     }, err => {
    //       this.service.hideSpinner();
    //     })
    //   }
    // }
    // end email 2fa
    else if (action == "email") {
      let url =
        "account/forget-password-mobile-app?email=" +
        this.loginForm.value.email;
      if (this.twoFAStatus == "EMAIL") {
        this.service.showSpinner();
        this.service.get(url).subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              localStorage.removeItem('Auth')
              localStorage.setItem('Auth', res['data'])
              $("#suggest").modal("hide");
              $("#emailAuth").modal("show");
              this.service.toasterSucc(
                "An OTP has been sent on your entered email ID"
              );
            } else {
              this.service.toasterErr(res["message"]);
            }
          },
          (err) => {
            this.service.hideSpinner();
            if (err["status"] == "500") {
              this.service.toasterErr("Email is not verified");
            } else {
              this.service.toasterErr(err["message"]);
            }
          }
        );
      } else {
        this.service.showSpinner();
        this.service.get(url).subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              localStorage.removeItem('Auth')
              localStorage.setItem('Auth', res['data'])
              $("#suggest").modal("hide");
              $("#emailAuth").modal("show");
              this.service.toasterSucc(res["message"]);
            } else {
              this.service.toasterErr(res["message"]);
            }
          },
          (err) => {
            this.service.hideSpinner();
            if (err["status"] == "500") {
              this.service.toasterErr("Email  is not verified");
            } else {
              this.service.toasterErr(err["message"]);
            }
          }
        );
      }
    }
  }
  // Verify Google / SMS Auth Functionality By Accounts
  verifyGoogleAuth(select) {
    if (select == "Google") {
      var apireq = {};
      var url = "";
      if (this.twoFAStatus == "GOOGLE") {
        url = "auth/verify-google";
        apireq = {
          otp: this.googleAuth,
          //"secretKey": this.secretKey
        };
      } else {
        url = "account/verify-google-code";
        apireq = {
          code: Number(this.googleAuth),
          secretKey: this.secretKey,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      this.service.showSpinner();
      this.service.post(url, apireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            localStorage.removeItem("Auth");
            if (this.twoFAStatus == "GOOGLE") {
              this.service.changeLoginSub("login");
              localStorage.removeItem('Auth')
              localStorage.setItem("Auth", res["data"]);
              sessionStorage.setItem("secretKey", this.secretKey);
            }
            this.service.toasterSucc("Google Authentication Verified");
            $("#googleAuth").modal("hide");
            this.router.navigate(["dashboard"]);
          } else {
            this.service.toasterErr("Wrong Google Authentication Code");
            this.googleAuth = "";
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.toasterErr("Something Went Wrong");
        }
      );
    } else if (select == "sms") {
      var smsapireq = {};
      var url = "";
      if (this.twoFAStatus == "SMS") {
        url = "auth/verify-sms";
        smsapireq = {
          otp: this.smsAuth,
          // 'username' : this.loginForm.value.email,
          // 'ipAddress': this.ipAddress,
          // 'source': 'WEB'
        };
      } else {
        url = "account/verify-sms-code";
        smsapireq = {
          otp: this.smsAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      this.service.showSpinner();
      this.service.post(url, smsapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuth").modal("hide");
            this.service.changeLoginSub("login");
            localStorage.removeItem('Auth')
            if (this.twoFAStatus == "SMS")
              localStorage.setItem("Auth", res["data"]);
            this.service.toasterSucc(res["message"]);
            this.router.navigate(["/dashboard"]);
          } else {
            this.service.toasterErr(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.toasterErr("Something Went Wrong");
        }
      );
    } else if (select == "email") {
      var emailapireq = {};
      var url = "";
      if (this.twoFAStatus == "EMAIL") {
        // url = 'account/verify-Email-Sms-code';
        url = `auth/verify-Email-code`;
        emailapireq = {
          otp: this.emailAuth,
        };
      } else {
        url = "auth/verify-Email-code";
        emailapireq = {
          otp: this.emailAuth,
          // 'emailOtp':this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      this.service.showSpinner();
      this.service.post(url, emailapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuth").modal("hide");
            this.service.changeLoginSub("login");
            localStorage.removeItem('Auth')
            if (this.twoFAStatus == "EMAIL")
              localStorage.setItem("Auth", res["data"]);
            this.service.toasterSucc(res["message"]);
            this.router.navigate(["/dashboard"]);
          } else {
            this.service.toasterErr(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.toasterErr("Something Went Wrong");
        }
      );
    }
    // window.location.reload()
  }

  // to remove token
  removeToken() {
    localStorage.removeItem("Auth");
  }


  // // to remove token
  // removeToken() {
  //   localStorage.removeItem("Auth");
  // }
}

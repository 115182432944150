import { Component, OnInit } from "@angular/core";
import { ServiceService } from "../service.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
declare var $: any;
@Component({
  selector: "app-kyc-management",
  templateUrl: "./kyc-management.component.html",
  styleUrls: ["./kyc-management.component.css"],
})
export class KycManagementComponent implements OnInit {
  searchText: string = "";
  pageNumber: number = 1;
  status: string;
  kycList: any = [];
  kycData: any = {};
  userKycId: any;
  kycDetail: any;
  itemsPerPage: any = 10;
  flip: boolean = false;
  countryList: any = [];
  country: any;
  transferForm: FormGroup;
  val: any
  flag: any
  dashStatus: any
  today: any = new Date().toISOString().slice(0, 16)

  constructor(public service: ServiceService, public router: Router, public activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(res => {
      this.flag = res.flag
      console.log(this.flag);
      if (this.flag == 'pending') {
        this.dashStatus = 'PENDING'
        console.log(this.dashStatus);
      } else if (this.flag == 'accepted') {
        this.dashStatus = 'ACCEPTED'
      } else if (this.flag == 'rejected') {
        this.dashStatus = 'REJECTED'
      }
    })
  }

  ngOnInit() {
    this.checkTransferFormValidations()
    this.getListOfKyc();
    this.getCountryList();
    this.getCountList();
  }
  

  // form validatioons 
  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'kycStatus': new FormControl(''),
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'search': new FormControl(''),
      'country': new FormControl(''),

    })
    if (this.flag == 'pending') {
      this.transferForm.patchValue({
        kycStatus: 'PENDING'
      })
    } else if (this.flag == 'accepted') {
      this.transferForm.patchValue({
        kycStatus: 'ACCEPTED'
      })
    } else if (this.flag == 'rejected') {
      this.transferForm.patchValue({
        kycStatus: 'REJECTED'
      })
    }
  }



  // reset() {
  //   if (
  //     this.transferForm.value.fromDate ||
  //     this.transferForm.value.toDate ||
  //     this.transferForm.value.search ||
  //     this.transferForm.value.kycStatus ||
  //     this.transferForm.value.country
  //   ) {
  //     this.transferForm.reset({
  //       fromDate: "",
  //       toDate: "",
  //       country: "",
  //       search: "",
  //       kycStatus: "",
  //     });
  //     this.getListOfKyc();
  //   }
  // }

  reset() {
    this.transferForm.reset({
      fromDate: "",
      toDate: "",
      country: "",
      search: "",
      kycStatus: "",
    });
    this.dashStatus = ""
    this.getListOfKyc();
  }

  // get country list
  getCountryList() {
    this.service.get("account/get-country-list").subscribe((res) => {
      if (res["status"] == 200) {
        this.countryList = res["data"];
      }
    });
  }
  gotoview(id) {
    this.router.navigate(["/kyc-action/id"], {
      queryParams: { id: id },
    });
  }
  // getStatus Function
  getStatus(event) {
    this.pageNumber = 1;
    this.status = event.target.value;
    if (this.status != "") {
      this.getListOfKyc();
    } else {
      this.getListOfKyc();
    }
  }


  ekFunctionZindgiKa() {
    let fromDate = Date.parse(this.transferForm.value.fromDate);
    let toDate = Date.parse(this.transferForm.value.toDate);
    let search = this.transferForm.value.search;
    let kycStatus = this.transferForm.value.kycStatus
    let country = this.transferForm.value.country
    let url = `account/admin/kyc-management/filter-kyc-users-list?page=${this.pageNumber - 1}&pageSize=${this.itemsPerPage}${this.transferForm.value.fromDate ? "&fromDate=" + fromDate : ""}${this.transferForm.value.toDate ? "&toDate=" + toDate : ""}${this.transferForm.value.country ? "&country=" + country : ""}${this.transferForm.value.kycStatus ? "&kycStatus=" + kycStatus : ""}${this.transferForm.value.search ? "&search=" + search : ""}`
    //  let zindgi  =  `wallet/admin/get-user-transaction-history-admin?amount=${this.transferForm.value.amount}&coinName=${this.transferForm.value.coinNameSe}&fromDate=${Math.round(new Date(this.transferForm.value.fromDate).getTime())}&page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}&status=${this.transferForm.value.status}&toDate=${Math.round(new Date(this.transferForm.value.toDate).getTime())}&txnHash=${this.transferForm.value.searchTextTxnHash}&txnType=${this.transferForm.value.type}`
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.kycList = res.data.list;
        }
        this.kycDetail = res['data'].totalCount
        //  this.totalRecords = res.data.totalCount;
        this.service.hideSpinner();
      },
      (err: any) => {
        this.service.hideSpinner();
        this.service.toasterErr(err.error.message);
      }
    );
  }

  // getListOFKYC Function
  getListOfKyc() {
    let url = `${"account/admin/kyc-management/filter-kyc-users-list?page=" +
      (this.pageNumber - 1) +
      "&pageSize=10"}${this.dashStatus ? "&kycStatus=" + this.dashStatus : ""}`;
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.kycDetail = res["data"].totalCount;
          this.kycList = res["data"]["list"];
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  exportAsXLSX(){
    let fromDate = Date.parse(this.transferForm.value.fromDate);
    let toDate = Date.parse(this.transferForm.value.toDate);
    let search = this.transferForm.value.search;
    let kycStatus = this.transferForm.value.kycStatus
    let country = this.transferForm.value.country
    let url = `account/admin/kyc-management/filter-kyc-users-list?page=${0}&pageSize=${this.kycDetail}${this.transferForm.value.fromDate ? "&fromDate=" + fromDate : ""}${this.transferForm.value.toDate ? "&toDate=" + toDate : ""}${this.transferForm.value.country ? "&country=" + country : ""}${this.transferForm.value.kycStatus ? "&kycStatus=" + kycStatus : ""}${this.transferForm.value.search ? "&search=" + search : ""}`
    let dataArr = [];
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.kycList = res.data.list;

          res['data']['list'].forEach((element, ind) => {

            dataArr.push({
              "S no": ind + 1,
              "First Name": element.firstName  ? element.firstName : '',
              "Last Name": element.lastName ? element.firstName : '',
              "Email": element.email ? element.email : 'N/A',
              "KYC ID": element.kycId ? element.kycId : 'N/A',
              "Country": element.country ? element.country : 'N/A',
              "Date & Time": element.createTime ? element.createTime.slice(0, 10) : 'N/A',
              "Status": element.userStatus ? element.userStatus : 'N/A',
            })
           
          })
      
          this.service.exportAsExcelFile(dataArr, 'KYC list');

        }
     
        //  this.totalRecords = res.data.totalCount;
        this.service.hideSpinner();
      },
      (err: any) => {
        this.service.hideSpinner();
        this.service.toasterErr(err.error.message);
      }
    );
  }

  // reset() {
  //     this.getListOfKyc('no-filter');
  //   }
  // }
  // Change Page Number
  changePageNumber(page) {
    this.pageNumber = page;

    this.ekFunctionZindgiKa();
  }

  // Get Particular KYC Detail
  getParticularKycDetail(userId, kycId) {
    this.userKycId = kycId;
    var url = "account/admin/kyc-management/get-kyc-details?userId=" + userId;
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.kycData = res["data"]["document"];

          $("#view").modal("show");
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  // Document Action  Functionality

  documentActionFunc(action, documentNumber, documentId) {
    var apiReq = {
      documentId: documentId,
      kycId: this.userKycId,
      reason: action == "ACCEPT" ? "Valid Document" : "Invalid Document",
      status: action,
    };
    this.service.showSpinner();
    this.service
      .post("account/admin/kyc-management/doc-status", apiReq)
      .subscribe(
        (res) => {
          this.service.hideSpinner();
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
  }

  disableSearchbtn() {

    if (this.transferForm.value.kycStatus || this.transferForm.value.fromDate || this.transferForm.value.toDate || this.transferForm.value.submit || this.transferForm.value.country || this.transferForm.value.search) {
      return false
    }
    else {
      return true
    }

  }
  dashboardData:any
  getCountList() {
     var url = "account/admin/dashboard/dashboard";
     this.service.showSpinner();
     this.service.get(url).subscribe(res => {
       this.service.hideSpinner();
       if (res['status'] == 200) {
          this.dashboardData = res['data'];
       } else {
         this.service.hideSpinner()
       }
     }, err => {
       this.service.hideSpinner();
       if (err['status'] == '401') {
         this.service.onLogout();
         this.service.toasterErr('Unauthorized Access');
       } else {
         this.service.toasterErr('Something Went Wrong');
       }
     })
   }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';
import { DatePipe } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var $: any;
@Component({
  selector: 'app-dispute-management',
  templateUrl: './dispute-management.component.html',
  styleUrls: ['./dispute-management.component.css']
})
export class DisputeManagementComponent implements OnInit {
  fromDate: any = ''
  twoDate: any = ''
  toDate:any = ''
  calender: any = { todate: '', fromdate: '' }
  minAge: Date;
  tradeStatus: any = ''
  disputeStatus:any=''
  country: any = ''
  paymentType: any = '';
  type: any = '';
  disputeList: any = [];
  itemsPerPage: number = 20;
  currentPage: number = 1
  totalItems: number;
  countryList: any = [];
  assignStaffForm: FormGroup;
  staffId: any;
  disputeId: any;
  staffListArray: any;
  today: any = new Date().toISOString().slice(0, 16)
  constructor(private router: Router, public service: ServiceService, private datePipe: DatePipe) { }

  ngOnInit() {
    var today = new Date();
    var minAge = 0;
    this.minAge = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate());
    this.getDisputeList()
    this.getCountryList()
    this.assignStaffFormValidations();
    this.getStaffList()
  }

  fromdate() {
    this.fromDate = new Date(this.calender.fromdate)
    this.fromDate = this.fromDate.getTime()
  }
  todate() {
    this.twoDate = new Date(this.calender.todate)
    this.twoDate = this.twoDate.getTime()
  }
  seachText:any
  // get dispute list 
  getDisputeList() {
    let apiReqData = {
      page: this.currentPage - 1,
      pageSize: 100,
      fromDate:this.fromDate,
      toDate:this.toDate,
      status: this.tradeStatus,
      country:  this.country,
      // search:  this.seachText
    }
    apiReqData = this.service.removeEmptyKey(apiReqData)
    let url = 'p2p-exchange/admin/search-and-filter-dispute'
    this.service.showSpinner();
    this.service.post(url, apiReqData).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.status == 200 || res.status == 201) {
        this.disputeList = res.data.list
        this.totalItems = res.data.size;
        this.totalItems = this.disputeList.length;
      }else if(res.status == 203){
        this.disputeList = []
        this.totalItems = res.data.size;
        this.totalItems = this.disputeList.length;
      }
       else {
        this.totalItems = 0
      }
    }, err => {
      this.service.hideSpinner();
      this.totalItems = 0
    })
  }

  // search 
  searchs() {
    if (this.tradeStatus || this.country  || this.type || this.fromDate || this.toDate) {
      this.getDisputeList()
    } else {
    }
  }
  // reset search 
  reset() {
    if (this.tradeStatus ||  this.country  || this.type || this.fromDate || this.toDate) {
      this.tradeStatus = ''
      this.country = ''
      this.paymentType = '';
      this.type = '';
      // this.calender = { todate: '', fromdate: '' }
      this.twoDate = ''
      this.fromDate = ''
      this.toDate = ''
      // this.seachText = ''
      this.getDisputeList()
    }
  }
  // pagination
  pagination(page) {
    this.currentPage = page;
    this.getDisputeList()
  }
  // get country list
  getCountryList() {
    this.service.get('account/get-country-list').subscribe((res) => {
      if (res['status'] == 200) {
        this.countryList = res['data']
      }
    })
  }
  // nevigate to trade details
  navigate(tradeId) {
    this.router.navigate(['/dispute-trades-detail', tradeId])
  }
  exportAsXLXS() {
    let dataArr = [];
    this.disputeList.forEach((element, ind) => {
      dataArr.push({
        'Trade ID': element.tradeId ? element.tradeId : 'N/A',
        'Dispute ID': element.disputeId ? element.disputeId : 'N/A',
        'Trade Date': element.creationTime ? this.datePipe.transform(element.creationTime) : 'N/A',
        'Dispute Date': element.disputeTime ? this.datePipe.transform(element.disputeTime) : 'N/A',
        'Dispute Status': element.disputeStatus ? element.disputeStatus : 'N/A',
        'Trade Amount': element.tradeAmount ? element.tradeAmount : 'N/A',
        'Staff Incharge': element.staffIncharge ? element.staffIncharge : 'N/A'
      })
    })
    this.service.exportAsExcelFile(dataArr, 'Dispute Management List')
  }
  // -------------- get staff list --------------------- //
  getStaffList() {
    let url = 'account/user-list'
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      if (res['status'] == 200 || res['status'] == 569) {
        this.staffListArray = res['data']
        this.service.hideSpinner();
      } else {
        this.service.hideSpinner();
      }
    }, (error: any) => {
      this.service.hideSpinner();
    })
  }
  assignStaffFormValidations() {
    this.assignStaffForm = new FormGroup({
      'staffName': new FormControl('', Validators.required),
    })
  }
  // --------------- assign dispute modal ----------------- //
  assignStaffModalOpen(disputeId, staffId) {
    this.service.showSpinner()
    this.assignStaffFormValidations();
    this.disputeId = disputeId
    this.assignStaffForm.patchValue({
      'staffName': staffId ? staffId : ''
    })
    setTimeout(() => {
      $('#assignStaffModal').modal('show');
      this.service.hideSpinner();
    }, 500);
  }
  // assign staff
  assignStaff() {
    let url = `p2p-exchange/admin/assign-staff-for-dispute?disputeId=${this.disputeId}&staffId=${this.assignStaffForm.value.staffName}`
    let data = {
    }
    this.service.showSpinner();
    this.service.post(url, data).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.service.hideSpinner();
        this.service.toasterSucc('Staff assign successfully.');
        this.assignStaffFormValidations()
        $('#assignStaffModal').modal('hide');
        this.getDisputeList()
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message);
      }
    }, (error: any) => {
      this.service.hideSpinner();
      this.service.toasterErr('Something went wrong.')
    })
  }


  disableSearchbtn(){

    if (this.tradeStatus || this.country  ||  this.fromDate || this.toDate)
     {
      return false
     }
     else {
       return true
     }
   }

}

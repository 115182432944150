import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any
@Component({
  selector: 'app-escrow-management',
  templateUrl: './escrow-management.component.html',
  styleUrls: ['./escrow-management.component.css']
})
export class EscrowManagementComponent implements OnInit {
  escrowList: any = [];
  coinlist: any = [];
  pageNumber: number = 1;
  pageSize: number = 20
  transferForm: FormGroup
  constructor(private router: Router, public service: ServiceService) { }

  ngOnInit() {
    this.checkTransferFormValidations()
    this.getTradeEscrowData()
    this.getCoinWalletList()
  }

  // form validatioons 
  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'kycStatus': new FormControl(''),
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'tradingPartner': new FormControl(''),
      'coinName': new FormControl(''),
      'transactionStatus': new FormControl(''),
    })
  }

  reset() {
    if (
      this.transferForm.value.fromDate ||
      this.transferForm.value.toDate ||
      this.transferForm.value.tradingPartner ||
      this.transferForm.value.kycStatus ||
      this.transferForm.value.coinName || this.transferForm.value.transactionStatus
    ) {
      this.transferForm.reset({
        fromDate: "",
        toDate: "",
        coinName: "",
        tradingPartner: "",
        kycStatus: "",
        transactionStatus: "",
      });
      this.getTradeEscrowData();
    }
  }
  getCoinWalletList() {
    this.service.showSpinner();
    // this.coinlist = [];
    this.service.get('wallet/coin/admin/get-full-coin-list').subscribe((res: any) => {
      this.service.hideSpinner()
      if (res.status == 200) {
        this.coinlist = res.data;
      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
      if (error['status'] == 401) {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      }
      else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  totalItems: any
  // Get List of User
  getEscrowList() {
    var url = "wallet/admin/get-escrow-list?page=" + (this.pageNumber - 1) + '&pageSize=' + this.pageSize;
    this.service.get(url).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.escrowList = res['data']['list']['content'];
        this.totalItems = res['data']['count']
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  disputedId: any
  cancelId: any
  release(id) {
    $('#ReleaseModal').modal('show')
    this.disputedId = id
  }
  cancel(id) {
    $('#CancelModal').modal('show')
    this.cancelId = id
  }
  //return coins in wallet of user1
  returnCoins() {
    this.service.get('wallet/wallet/request-cancel-ESCROW?userToAdminTransferId=' + this.cancelId).subscribe(res => {
      if (res['status'] == 200) {
        this.service.toasterSucc(res['message'])
        $('#CancelModal').modal('hide')
        this.getEscrowList()
      } else {
        this.service.toasterErr(res['message'])
      }
    })
  }
  // release coins to user2
  releaseCoins() {
    var url = "wallet/admin/approvedBy-user1?referenceId=" + this.disputedId;
    this.service.post(url, 1).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.service.toasterSucc(res['message'])
        $('#ReleaseModal').modal('hide')
        this.getEscrowList()
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  // trade escrow data list 
  getTradeEscrowData(page?) {
    let currentPage = page ? page : this.pageNumber
    var url = "p2p-exchange/admin/Escrow-List?page=" + (currentPage - 1) + '&pageSize=' + this.pageSize;
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.escrowList = res['data']['Data']['content'] || res['data']['Data'] ||  '';
        this.totalItems = res['data']['Data']['totalElements'] || res['data']['Count'] || '';
      }else{
        this.escrowList = []
      }
    }, err => {
      this.service.hideSpinner();
      this.escrowList = []
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  ekFunctionZindgiKa(page?) {
    let currentPage = page ? page : this.pageNumber
    let kycStatus = this.transferForm.value.kycStatus
    let transactionStatus = this.transferForm.value.transactionStatus
    let coinName = this.transferForm.value.coinName
    let tradingPartner = this.transferForm.value.tradingPartner
    let url = `p2p-exchange/admin/Escrow-List?page=${currentPage - 1}&pageSize=${this.pageSize}${this.transferForm.value.kycStatus ? "&statusType=" + kycStatus : ""}${this.transferForm.value.transactionStatus ? "&transactionStatus=" + transactionStatus :""}${this.transferForm.value.coinName ? "&coinName=" + coinName : ""}${this.transferForm.value.tradingPartner ? "&tradingPartner=" + tradingPartner : ""}`
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res.status == 200) {
          this.escrowList = res['data']['Data']['content'] || res['data']['Data'] ||  '';
          this.totalItems = res['data']['Data']['totalElements'] || res['data']['Count'] || '';
        }else{
        this.escrowList = []
        }
        this.service.hideSpinner();
      },
      (err: any) => {
        this.escrowList = []
        this.service.hideSpinner();
        this.service.toasterErr(err.error.message);
      }
    );
  }

  disableSearchbtn(){

    // if (this.tradeStatus || this.coinName  ||  this.fromDate || this.toDate)


    if (
      this.transferForm.value.fromDate ||
      this.transferForm.value.toDate ||
      this.transferForm.value.tradingPartner ||
      this.transferForm.value.kycStatus ||
      this.transferForm.value.coinName || this.transferForm.value.transactionStatus
    )
     {
      return false
     }
     else {
       return true
     }
   }

   performAction(){
    
   }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-edit-faq',
  templateUrl: './edit-faq.component.html',
  styleUrls: ['./edit-faq.component.css']
})
export class EditFaqComponent implements OnInit {
  addFaqForm: FormGroup;
  pageNumber: number = 1
  faqId: any;
  editData: any;
  currTab: any;
  constructor(public route: Router, public service: ServiceService, public active: ActivatedRoute) {
    this.active.queryParams.subscribe((res: any) => {
      this.faqId = res.faqId;
      this.currTab = res.lan;
    })
  }

  ngOnInit(): void {
    this.formValidation();
    this.editFaq()
  }

  formValidation() {
    this.addFaqForm = new FormGroup({
      'title': new FormControl('', [Validators.required, Validators.pattern(/^(?!.*\b(\S{15,})\b).*$/), Validators.maxLength(150)]),
      'description': new FormControl('', [Validators.required, Validators.pattern(/^(?!.*\b(\S{15,})\b).*$/),Validators.maxLength(350)])
    })
  }
  // edit faq language
  editFaqLanguage() {
    if (this.currTab == 'English') {
      this.editFaq();
    }
    else if (this.currTab == 'German') {
      this.editFaqGerman();
    }
    else if (this.currTab == 'Spanish') {
      this.editFaqSpanish();
    }
  }
  // edit faq english
  editFaq() {
    this.service.get("static/admin/static-content/get-FAQ-by-id?faqId=" + this.faqId).subscribe((res: any) => {
      if (res.status = 200) {
        this.editData = res.data;
        this.addFaqForm.patchValue({
          'title': this.editData.question,
          'description': this.editData.answer
        })
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  // edit faq german
  editFaqGerman() {
    this.service.get("static/get-german-faq-detail?faqId=" + this.faqId).subscribe((res: any) => {
      if (res.status = 200) {
        this.editData = res.data;
        this.service.toasterSucc(res.message);
        this.addFaqForm.patchValue({
          'title': this.editData.question,
          'description': this.editData.answer
        })
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  // edit faq spanish
  editFaqSpanish() {
    this.service.get("static/get-spanish-faq-detail?faqId=" + this.faqId).subscribe((res: any) => {
      if (res.status = 200) {
        this.editData = res.data;
        this.service.toasterSucc(res.message);
        this.addFaqForm.patchValue({
          'title': this.editData.question,
          'description': this.editData.answer
        })
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  //  update faq language
  updateFaqLanguage() {
    if (this.currTab == 'English') {
      this.updateFaq();
    }
    else if (this.currTab == 'German') {
      this.updateFaqGerman();
    }
    else if (this.currTab == 'Spanish') {
      this.updateFaqSpanish();
    }
  }
  // update faq english
  updateFaq() {
    let request = {
      'answer': this.addFaqForm.value.description,
      'question': this.addFaqForm.value.title,
      "faqId": this.faqId,
      "topicKey": "string",
      "topicName": "string",

    }
    this.service.post("static/admin/static-content/update-FAQ-by-id", request).subscribe((res: any) => {
      if (res.status = 200) {
        this.service.toasterSucc(res.message)
        this.route.navigate(['/faq-management'])
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  // update faq german
  updateFaqGerman() {
    let request = {
      'answer': this.addFaqForm.value.description,
      'question': this.addFaqForm.value.title,
    }
    this.service.post("static/update-german-faq?faqId=" + this.faqId, request).subscribe((res: any) => {
      if (res.status = 200) {
        this.service.toasterSucc(res.message)
        this.route.navigate(['/faq-management'])
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  // update faq spanish
  updateFaqSpanish() {
    let request = {
      'answer': this.addFaqForm.value.description,
      'question': this.addFaqForm.value.title,
    }
    this.service.post("static/update-spanish-faq?faqId=" + this.faqId, request).subscribe((res: any) => {
      if (res.status = 200) {
        this.service.toasterSucc(res.message)
        this.route.navigate(['/faq-management'])
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
}

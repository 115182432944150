import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
declare var $
@Component({
  selector: 'app-admin-bank-list',
  templateUrl: './admin-bank-list.component.html',
  styleUrls: ['./admin-bank-list.component.css']
})
export class AdminBankListComponent implements OnInit {
  staffForm: FormGroup
  currTab: any = 'HOT'
  itemPerPage: any = 10
  pageNumber: any = 1
  checkData: any
  constructor(private router: Router, public service: ServiceService) { }

  ngOnInit() {
    this.adminFund()
  }

  fundDataArray: any = []
  adminFund() {
    let url = `wallet/get-admin-bank-accounts?page=${this.pageNumber -1}&pageSize=${this.itemPerPage}`
    this.service.showSpinner()
    this.service.get(url).subscribe((res) => {

      if (res['status'] == 200) {
        this.service.hideSpinner();
        this.fundDataArray = res['data']['list']
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['message'])
      }
    })
  }
  /** to switch between tabs */
  selectTab(tab) {
    this.currTab = tab;
    if (tab == 'HOT') {
      this.adminFund()
    } else {
      this.getUpiData()
    }
  }
  upiDataArray: any = []
  getUpiData() {
    let url = `wallet/admin/upi-List-admin`
    this.service.showSpinner()
    this.service.get(url).subscribe((res) => {

      if (res['status'] == 200) {
        this.service.hideSpinner();
        this.upiDataArray = res['data']
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['message'])
      }
    })
  }
  changeBankStatus(bankId, isVisible) {
    let url = `wallet/admin/bank-status?bankId=${String(isVisible)}&isVisible=${bankId}`
    this.service.showSpinner()
    this.service.postApi(url, {}).subscribe((res) => {
      if (res['status'] == 200) {
        this.service.hideSpinner();
        this.adminFund()
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['message'])
      }
    })
  }
  changeUpiStatus(upiId, isVisible) {
    let url = `wallet/admin/upi-status?isVisible=${upiId}&upiId=${String(isVisible)}`
    this.service.showSpinner()
    this.service.postApi(url, {}).subscribe((res) => {
      if (res['status'] == 200) {
        this.service.hideSpinner();
        this.getUpiData()
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['message'])
      }
    })
  }

  bankID:any
  openModal(bankId) {
    this.bankID = bankId;
      $('#deleteModal').modal('show')
  }
  deleteBank(){
    let url = `wallet/delete-user-bank-account?bankDetailId=${this.bankID}`
    this.service.delete(url).subscribe((res)=>{
      if (res['status'] == 200) {
        this.service.hideSpinner();
        this.adminFund()
      $('#deleteModal').modal('hide')
      this.service.toasterSucc('Admin bank deleted successfully')
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['message'])
      }
    })
  }
  navigateToEdit(id){
    this.router.navigate(['/edit-admin-bank'],{queryParams : {id:id}})
  }
  bankId:any
  action:any
   // Delete / Block Function
   openModals(action, bankId) {
    this.bankId = bankId;
    this.action = action;
    if (action == 'ACTIVE') {
      $('#active').modal('show')

    } else if (action == 'BLOCK') {
      $('#block').modal('show')
    }
  }
  performAction() {
    var url = `wallet/admin/enable-disable?bankId=${this.bankId}`;
    this.service.showSpinner();
    this.service.post(url, '').subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        if (this.action == 'BLOCK') {
          $('#block').modal('hide');
          this.service.toasterSucc('Account blocked successfully');
        }
        else {
          $('#active').modal('hide');
          this.service.toasterSucc('User activated successfully');
        }
        this.adminFund()
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
}

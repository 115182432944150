import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-edit-admin-bank',
  templateUrl: './edit-admin-bank.component.html',
  styleUrls: ['./edit-admin-bank.component.css']
})
export class EditAdminBankComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl
  
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  currTab:any = 'HOT';
  bankDetailId:any
  addSwatchesFormUpi:FormGroup
  constructor(private router: Router, public commonService: ServiceService,private activatedRoute:ActivatedRoute) { 
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.bankDetailId = res.id
    })
  }

  ngOnInit() {
    this.addSwatchesFormValidation();
  this.addUpiForm()
  this.getBankDetails()
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'bankName': new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z ]{3,60}$/)]),
      'accountNumber': new FormControl('', [Validators.required]),
      'status': new FormControl('', [Validators.required]),
      'terms': new FormControl('', [Validators.required]),
      'branch': new FormControl('', [Validators.required,Validators.pattern(/^[a-zA-Z ]{3,60}$/)]),
      'accountHolderName': new FormControl('',[Validators.required,Validators.pattern(/^[a-zA-Z ]{3,30}$/)]),
      'ifscCode': new FormControl('',[Validators.required, Validators.pattern(/^[A-Z]{4}0[A-Z0-9]{6}$/)])
    })
  }
addUpiForm(){
  this.addSwatchesFormUpi =  new FormGroup({
    'name': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/)]),
    'upiID': new FormControl('', [Validators.required]),
  })
}
  addSwatches() {
    let url = `wallet/update-user-bank-details?bankDetailId=${this.bankDetailId}`
    let data = {
      "accountHolderName": this.addSwatchesForm.value.accountHolderName,
      "accountNo": this.addSwatchesForm.value.accountNumber,
      "bankName": this.addSwatchesForm.value.bankName,
      "ifsc": this.addSwatchesForm.value.ifscCode,
      "accountType": "string",
      "contactNo": "string",
      "ibanNo": "string",
      "imageUrl": "string",
      "swiftNo": "string",
      'branchName' : this.addSwatchesForm.value.branch,
      'paymentTerms' : this.addSwatchesForm.value.terms,
      'bankStatus' : this.addSwatchesForm.value.status,
    }
    this.commonService.showSpinner();
    this.commonService.putApi(url,data).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/admin-bank-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  addUpi() {
    let url = `wallet/admin/add-Upi-details`
    let data = {
      'name': this.addSwatchesFormUpi.value.name,
      'upiId': this.addSwatchesFormUpi.value.upiID,
    }
    this.commonService.showSpinner();
    this.commonService.post(url,data).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/admin-bank-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  viewDetails :any 
  selectTab(tab) {
    this.currTab = tab;
    if(tab == 'HOT'){
    }else{
    }
}
getBankDetails(){
  let url = `wallet/admin/get-bank-account-detail?bankDetailsId=${this.bankDetailId}`
  this.commonService.showSpinner();
  this.commonService.get(url).subscribe((res: any) => {
    if (res.status == 200) {
      this.commonService.hideSpinner();
      this.commonService.toasterSucc(res.message);
      this.viewDetails = res.data 
      this.addSwatchesForm.patchValue({
        "accountHolderName": this.viewDetails.accountHolderName,
        "accountNumber": this.viewDetails.accountNo,
        "bankName": this.viewDetails.bankName,
        "ifscCode": this.viewDetails.ifsc,
        "branch": this.viewDetails.branchName,
        "status": this.viewDetails.bankStatus,
        "paymentTerms": this.viewDetails.terms,

      })
    } else {
      this.commonService.hideSpinner();
      this.commonService.toasterErr(res.message)
    }
  },(err=>{
    this.commonService.hideSpinner();

  }))
}
}

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-xindia-holding-add',
  templateUrl: './xindia-holding-add.component.html',
  styleUrls: ['./xindia-holding-add.component.css']
})
export class XindiaHoldingAddComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl
  coinName:any
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  constructor(private router: Router, public commonService: ServiceService,private httpClient:HttpClient,public active:ActivatedRoute) { 
    this.active.queryParams.subscribe((res:any)=>{
      this.coinName=res.coinName;
    }) }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.getXindiaData()
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'tradingFeeDiscount': new FormControl('', [Validators.required]),
      'otcDiscount': new FormControl('', [Validators.required]),
      'toBecome': new FormControl('', [Validators.required]),
      'weekly': new FormControl('', [Validators.required]),
    })
  }
  addSwatches() {

    let url
    // let 
    if(this.xindiaId){
      url = `static/admin/update-HoldXindia?holdxindiaId=${this.xindiaId}`
    }else{
      url = `static/admin/add-hold-Xindia`
    }
    let data = {
      // 'coinImage': this.imageUrl,
      'tradingFeeDiscount': this.addSwatchesForm.value.tradingFeeDiscount,
      'otcDiscount': this.addSwatchesForm.value.otcDiscount,
      'toBecome': this.addSwatchesForm.value.toBecome,
      'weekly': this.addSwatchesForm.value.weekly,
    }
    // this.commonService.showSpinner();
    this.commonService.post(url, data).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.status == 200) {
        this.getXindiaData()
        this.commonService.toasterSucc(res.message);
        this.commonService.hideSpinner();

        this.router.navigate(['/fierex-details-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  xindiaArray:any = []
  xindiaId:any  []
getXindiaData(){
  let url = `static/get-HoldXindia`
  // this.commonService.showSpinner();
  this.commonService.get(url).subscribe((res: any) => {
    console.log("add category response ==>", res)
    if (res.status == 200) {
      this.xindiaArray = res['data'][0]
      this.xindiaId = res['data'][0]['holdXindiaId']
      // this.commonService.toasterSucc(res.message);
      this.addSwatchesForm.patchValue({
        tradingFeeDiscount: this.xindiaArray.tradingFeeDiscount,
        otcDiscount : this.xindiaArray.otcDiscount,
        toBecome : this.xindiaArray.toBecome,
        weekly: this.xindiaArray.weekly,
      })
      // this.imageUrl = this.xindiaArray.coinImage
      this.commonService.hideSpinner()
      // this.router.navigate(['/fee-management'])
    } else {
      this.commonService.hideSpinner();
      this.commonService.toasterErr(res.message)
    }
  })
}
  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      // this.commonService.hideSpinner();
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }
}
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ServiceService } from "../service.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Socket } from "ngx-socket-io";
import { ChatService } from "src/app/chat.service";
declare var $: any;

@Component({
  selector: "app-dispute-trade-details",
  templateUrl: "./dispute-trade-details.component.html",
  styleUrls: ["./dispute-trade-details.component.css"],
})
export class DisputeTradeDetailsComponent implements OnInit {
  @ViewChild("scrollMe", { static: true })
  private myScrollContainer: ElementRef;
  @ViewChild("scrollMeAdminChat", { static: true })
  private myScrollContainerAdminChat: ElementRef;

  tradeId: any;
  obj: any = {};
  tradeDetails: any;
  toEmail: string;
  adminData: any;
  adminEmail: any;
  currentDate: Date;
  buyerSeller: any;
  fkUserId: any;
  partnerId: any;
  takeAction: boolean;
  takeDipute: boolean = false;
  recommendedAction: boolean;
  remarkForm: FormGroup;
  chatHistory: any = [];
  disputeId: any;
  adminId: any;
  toUserId: any;
  chatInitAndSubscribe: boolean = false;
  timerEvidence: any = "";
  timerForEvidence: any;
  timerArray: any[] = [0.5, 1, 2, 3, 6, 12, 24, 48, 72, 96, 120, 144, 168];
  imageSendInChat: any;

  // pagination
  itemsPerPageDispute: any = 5;
  currentPageDispute: any = 1;
  totalItemsDispute: any;
  disputeHistoryArray: any[] = [];
  itemsPerPageTrade: any = 5;
  currentPageTrade: any = 1;
  totalItemsTrade: any;
  tradeHistoryArray: any[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public service: ServiceService,
    public chatService: ChatService,
    public socket: Socket
  ) {
    this.activatedRoute.params.subscribe((res) => {
      if (res.id) {
        this.tradeId = res.id;
      }
    });
  }

  ngOnInit() {
    this.socket.connect();
    this.service.chatArr = [];
    this.chatHistory = [];
    this.adminId = localStorage.getItem("userId");
    this.currentDate = new Date();
    this.getTradeDetails();
    this.remarkFormValidation();
    this.receiveMessage();
  }

  scrollToBottom() {
    setTimeout(() => {
      try {
        this.myScrollContainer.nativeElement.scrollTop =
          this.myScrollContainer.nativeElement.scrollHeight;
      } catch (err) {}
    }, 10);
  }

  disputedUsers : any = []
  getTradeDetails() {
    this.service.showSpinner();
    this.service
      .get(
        `p2p-exchange/admin/get-trade-details-by-tradeid?tradeId=${this.tradeId}`
      )
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.tradeDetails = res["data"].RESULT_LIST;
            this.buyerSeller = res["data"].RESULT_LIST.type;
            this.disputeId = res["data"].RESULT_LIST.disputeId;
            this.fkUserId = res["data"].RESULT_LIST.fkUserId;
            this.partnerId = res["data"].RESULT_LIST.partnerId;
            this.disputedUsers = [
              {
                userName : res["data"].RESULT_LIST.buyer,
                userId :  res["data"].RESULT_LIST.partnerId,
              },
              {
                userName : res["data"].RESULT_LIST.seller,
                userId :  res["data"].RESULT_LIST.fkUserId,
              }
            ]
            this.getChatHistory();
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
  }

  getChatHistory() {
    let url = `notification/get-chat-history-through-admin?tradeId=${this.tradeId}&adminId=${this.fkUserId}&userId=${this.partnerId}`;
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 1620) {
          this.chatHistory = res["data"];
          this.scrollToBottom();
        }
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }

  // take dispute
  takeDisputeByAdmin() {
    this.takeDipute = true;
  }

  recommendedActionByAdmin() {
    $("#recommendedActionModal").modal("show");
  }

  remarkFormValidation() {
    this.remarkForm = new FormGroup({
      remark: new FormControl("", Validators.required),
    });
  }

  recommendedActionByAdminSave() {
    let url = `p2p-exchange/admin/recommendation?disputeId=${this.disputeId}&recommendation=${this.remarkForm.value.remark}`;
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        if (res["status"] == 200) {
          this.service.hideSpinner();
          this.service.toasterSucc(res.message);
          $("#recommendedActionModal").modal("hide");
          this.remarkForm.reset();
          this.getTradeDetails();
        } else {
          this.service.hideSpinner();
          this.service.toasterErr(res.message);
        }
      },
      (error: any) => {
        this.service.hideSpinner();
        this.service.toasterErr(error.message);
      }
    );
  }
  // select user for chat
  selectUserForChat(event) {
    this.toUserId = event.target.value;
    if (this.chatInitAndSubscribe == false) {
      this.service.initSocketChat(); // initialise chat socket
      this.scrollToBottomAdminChat();
      this.chatInitAndSubscribe = true;
    }
    this.service.chatArr = [];
    this.timerForEvidence = "";
    this.timerEvidence = "";
    this.obj.chat = "";
    this.chatHistoryAdminAndUser(); // chat between admin and buyer/seller
    this.scrollToBottomAdminChat();
    this.service.getMessage().subscribe((res) => {
      this.scrollToBottomAdminChat();
    });
  }
  // get chat history of admin and buyer/seller
  chatHistoryAdminAndUser() {
    let url = `notification/get-chat-history-through-admin?tradeId=${this.tradeId}&adminId=${this.adminId}&userId=${this.toUserId}`;
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 1620) {
          this.chatHistory = res["data"];
          this.chatService.particularChat = this.chatHistory;

          this.scrollToBottomAdminChat();
        }
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }
  // scroll chat to bottom of container
  scrollToBottomAdminChat() {
    setTimeout(() => {
      try {
        this.myScrollContainerAdminChat.nativeElement.scrollTop =
          this.myScrollContainerAdminChat.nativeElement.scrollHeight;
      } catch (err) {}
    }, 10);
  }
  // send chat
  sendChat() {
    if (this.toUserId == "" || this.toUserId == undefined) {
      return this.service.toasterErr("Please select user.");
    } else {
      var data;
      if (this.obj.chat) {
        data = {
          userId: this.adminId,
          toUserId: this.toUserId,
          messageFormat: "TEXT",
          message: this.obj.chat,
          tradeId: this.tradeId,
          timerForEvidence: this.timerForEvidence,
          role: "ADMIN",
          returnUrl: `${this.service.webUrl}admin-chat?tradeId=${this.tradeId}&toUserId=${this.adminId}`,
        };

        data = this.service.removeEmptyKey(data);
        // this.service.wsChat.send(JSON.stringify(data))
        this.chatService.sendMessage(data);
        this.chatService.particularChat.push(data);
        this.service.chatArr.push(data);
        this.scrollToBottomAdminChat();
        this.obj.chat = "";
        this.timerForEvidence = "";
      }
    }
  }
  receiveMessage() {
    console.log("receive message chat");
    this.chatService.getMessageNew().subscribe((message: any) => {
      console.log("receive message222 ==>", message);
      let newMessage = message;
      this.chatService.particularChat.push(newMessage);
    });
    this.scrollToBottom();
  }

  // ask for evidence timer
  timerForEvidenceAsk(event) {
    this.timerForEvidence = event.target.value;
    if (this.timerForEvidence == "") {
      return;
    }
    this.obj.chat =
      "Please submit your evidence within " + this.timerForEvidence + " hour";
  }

  // send image in chat
  handleFileInput(event) {
    if (this.toUserId == "" || this.toUserId == undefined) {
      return this.service.toasterErr("Please select user.");
    } else {
      if (event.target.files && event.target.files[0]) {
        var type = event.target.files[0].type;
        if (
          type === "image/png" ||
          type === "image/jpg" ||
          type === "image/jpeg" ||
          type === "application/pdf"
        ) {
          let fileData = event.target.files[0];
          this.sendFormData(fileData);
        } else {
          this.service.toasterErr("Select only jpg, jpeg and png file.");
        }
      }
    }
  }

  sendFormData(fileData) {
    this.service.toasterSucc("Please wait! Upload in progress...");
    let formdata = new FormData();
    formdata.append("file", fileData);
    this.service.post("account/upload-file", formdata).subscribe((res: any) => {
      if (res.status == 200) {
        this.imageSendInChat = res.data;
        let data = {
          userId: this.adminId,
          toUserId: this.toUserId,
          messageFormat: "IMAGE",
          message: this.imageSendInChat,
          tradeId: this.tradeId,
        };
        this.chatService.sendMessage(data);
        this.chatService.particularChat.push(data);
        this.service.chatArr.push(data);
        this.scrollToBottomAdminChat();
        this.imageSendInChat = "";
      }
    });
  }
  // action taken by admin/staff
  takeActionByAdmin() {
    $("#takeActionModal").modal("show");
  }
  releaseForDetailsModal() {
    $("#takeActionModal").modal("hide");
    $("#releaseEscrowModal").modal("show");
  }

  releaseForDetails() {
    this.service.showSpinner();
    this.service
      .post(
        `/p2p-exchange/admin/release-bitcoins-by-admin?tradeId=${this.tradeId}`,
        ""
      )
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.tradeDetails = res["data"].RESULT_LIST;
            $("#releaseEscrowModal").modal("hide");
            this.service.toasterSucc(res["message"]);
          } else {
            $("#releaseEscrowModal").modal("hide");
            this.service.toasterSucc(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
  }
  evidenceList : any = []
  getEvidence(id){
    let url = `p2p-exchange/admin/evidences?tradeId=${this.tradeId}&userId=${id}`
   
 
    this.service.get(url).subscribe(
      (res : any) => {
       
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.evidenceList = res.data
          // this.service.toasterSucc(res["message"]);
        } else {
          this.evidenceList = []
          // this.service.toasterErr(res["message"]);
        }
      },
      (err) => {

        this.service.hideSpinner();
      }
    );
  }
  openImage(url){
    let a = document.createElement('a')
    a.href = url
    a.target = "_blank"
    a.click()
    a.append()
  }
  ngOnDestroy() {
    // this.chatService.particularChat = [];
    this.chatService.disconnect();
  }
}

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-listing-coin-edit',
  templateUrl: './listing-coin-edit.component.html',
  styleUrls: ['./listing-coin-edit.component.css']
})
export class ListingCoinEditComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl
  coinName: any
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  constructor(private router: Router, public commonService: ServiceService, private httpClient: HttpClient, public active: ActivatedRoute) {
    this.active.queryParams.subscribe((res: any) => {
      this.coinName = res.coinName;
    })
  }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.getCoinDetailsData()
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      // 'colorName': new FormControl('', [Validators.required]),
      'contractAddress': new FormControl('', [Validators.required, Validators.pattern(/^0x[a-fA-F0-9]{40}$/)]),
      'coinFullName': new FormControl('', [Validators.required]),
      'coinShortName': new FormControl('', [Validators.required]),
      'coinType': new FormControl(''),
      'network': new FormControl('', [Validators.required]),
      'coinImage': new FormControl(''),
      'category': new FormControl('')
    })
  }





  addSwatches() {

    // this.router.navigate(['/swatches/list-swatches'])
    // let url = `wallet/coin/admin/add-Coin?network=${this.addSwatchesForm.value.network}`
    let url = `wallet/coin/coin-data-update?coinId=${this.coinIdData}&network=${this.addSwatchesForm.value.network}`
    let data = {
      'contractAddress': this.addSwatchesForm.value.contractAddress,
      'coinFullName': this.addSwatchesForm.value.coinFullName,
      'coinShortName': String(this.addSwatchesForm.value.coinShortName).toUpperCase(),
      'coinType': this.addSwatchesForm.value.coinType,
      'coinImage': this.imageUrl,
      'category': this.addSwatchesForm.value.category
    }
    this.commonService.showSpinner();
    this.commonService.post(url, data).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.status == 200) {
        this.storageUpdate(String(this.addSwatchesForm.value.coinShortName).toUpperCase())
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/fee-management'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  dataCoinFetch: any
  coinIdData: any
  isToken = true
  isFiat = false
  getCoinDetailsData() {
    var url = `wallet/coin/get-coin-details?coinName=${this.coinName}`
    this.commonService.showSpinner();
    this.commonService.get(url).subscribe(res => {

      this.commonService.hideSpinner();
      if (res['status'] == 200) {

        this.dataCoinFetch = res['data']
        this.coinIdData = res['data']['coinId']
        this.addSwatchesForm.patchValue({
          contractAddress: this.dataCoinFetch.contractAddress,
          coinFullName: this.dataCoinFetch.coinFullName,
          coinShortName: this.dataCoinFetch.coinShortName,
          coinType: this.dataCoinFetch.coinType,
          network: this.dataCoinFetch.network
        })
        this.isToken = true
       
        if(this.dataCoinFetch.networkList){
          for (let i = 0; i < this.dataCoinFetch.networkList.length; i++) {
            console.log("opopopopopopo", this.dataCoinFetch.networkList[i]);
  
            if (!this.dataCoinFetch.networkList[i].contractAddress) {
              this.isToken = false
              let contractAddress = this.addSwatchesForm.get('contractAddress')
              contractAddress.setValidators(null)
              contractAddress.updateValueAndValidity()
              let network = this.addSwatchesForm.get('network')
              network.setValidators(null)
              network.updateValueAndValidity()
              break
            }
  
          }
        }
        else{
          this.isToken = false
          this.isFiat = true
          let contractAddress = this.addSwatchesForm.get('contractAddress')
          contractAddress.setValidators(null)
          contractAddress.updateValueAndValidity()
          let network = this.addSwatchesForm.get('network')
          network.setValidators(null)
          network.updateValueAndValidity()
        }
       
        this.imageUrl = this.dataCoinFetch.coinImage
        if(this.isToken){
          this.addSwatchesForm.patchValue({
            network : this.dataCoinFetch.networkList[0].networks,
            contractAddress :  this.dataCoinFetch.networkList[0].contractAddress
          })
        }
        //  this.router.navigate(['/edit-coin'])
        // this.commonService.toasterSucc('Coin Deleted Successfully');
        // this.getCoinWalletList();
        // this.getcoinPairlist();
      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }

  getContractAddress(e){
    for (let i = 0; i < this.dataCoinFetch.networkList.length; i++) {
      if(this.dataCoinFetch.networkList[i].networks == e){
        this.addSwatchesForm.patchValue({
          contractAddress :  this.dataCoinFetch.networkList[i].contractAddress
        })
      }
    }
  }

  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      // this.commonService.hideSpinner();
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Something Went Wrong');
      }
    })
  }

  dataArray: any
  checkWalletAddress($evant) {
    let url = `https://api.pancakeswap.info/api/v2/tokens/${this.addSwatchesForm.value.contractAddress}`
    this.httpClient.get(url).subscribe(res => {
      this.dataArray = res['data']
      this.addSwatchesForm.patchValue({
        coinShortName: this.dataArray.symbol,
        coinFullName: this.dataArray.name
      })
    })
  }
  storageUpdate(coin) {
    let data = {
      "coinName": coin,
      "storageType": 'HOT'
    }
    this.commonService.showSpinner();
    this.commonService.post('wallet/admin/hot-cold-storage/create-storage-wallet', data).subscribe((res) => {
      this.commonService.hideSpinner();
      if (res['status'] == 200) {
        this.commonService.toasterSucc(res['message'])
        this.newAddressGenerate(coin)
      }
      else {
        this.commonService.toasterErr(res['message']);
        this.commonService.hideSpinner();
      }
    }, (err) => {
      this.commonService.hideSpinner();
    })
  }

  newAddressGenerate(coin) {
    let data = {
      "accName": 1,
      "coinName": coin,
      "storageType": 'HOT'
    }
    this.commonService.showSpinner();
    this.commonService.post('wallet/admin/hot-cold-storage/get-new-storage-address', data).subscribe((res) => {
      this.commonService.hideSpinner();
      if (res['status'] == 200) {
        this.commonService.toasterSucc(res['message'])
      }
      else {
        this.commonService.toasterErr(res['message']);
        this.commonService.hideSpinner();
      }
    }, (err) => {
      this.commonService.hideSpinner();
    })
  }
}

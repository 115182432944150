import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

import { FormGroup, FormControl } from "@angular/forms";
import { ServiceService } from 'src/app/service.service';
declare var $
@Component({
  selector: 'fee-manage-new',
  templateUrl: './fee-manage-new.component.html',
  styleUrls: ['./fee-manage-new.component.css']
})
export class FeeManageNewComponent implements OnInit {
  pageNumber: number = 1;
  privilegePrice: any;
  twoDate: any;
  currencycoin: any = "BTC";
  allcoin = "BTC";
  Hggcoin: any;
  show: boolean = false;
  time: any;
  profitobj: any = {};
  walletCheckFee: any = [];
  type = "WITHDRAW";
  optionFee: any = "WITHDRAW";
  currTab: any = 'Fee';
  numRegxForDot = /^\d{0,6}(\.\d{1,6})?$/;
  btcdata: any = {};
  calender: any = { todate: "", formdate: "" };
  minAge: Date;
  feecoinArry: any = [];
  mininArry: any = [];
  btcFeeVali: any;
  fromDate: any;
  coinlist: any = [];
  coinListArr: any = [];
  loopdata: any = [];
  manageCoins: boolean = true;
  manageTrading: boolean = true;
  particularData: any = [];
  editedCoinName: any;
  manageCoinForm: FormGroup;
  manageCoinPair: FormGroup;
  particularDatad: any = [];
  coinpairlistarray: any = [];
  baseCoinn: any;
  executableCoinn: any;
  visiblen: boolean;
  constructor(private router: Router, public service: ServiceService) { }

  ngOnInit() {
    this.manageCoinForm = new FormGroup({
      isVisible: new FormControl(""),
      // remark: new FormControl(""),
      istransfer: new FormControl(""),
      p2pStatus: new FormControl(""),
    });
    this.manageCoinPair = new FormGroup({
      pairedCoin: new FormControl(""),
      visible: new FormControl(""),
      baseCoin: new FormControl(""),
    });
    this.defaults();
    this.getCoinWalletList();
    var today = new Date();
    var minAge = 0;
    this.minAge = new Date(
      today.getFullYear() - minAge,
      today.getMonth(),
      today.getDate()
    );
  }


  // this.coinNetworkArray = []
  getCoinWalletList() {
    this.service.showSpinner();
    this.service.get("wallet/coin/admin/get-full-coin-list").subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.coinlist = res.data;

          this.coinlist.forEach((element) => {
            this.coinListArr.push(element.coinShortName);
          });
          // this.coinNetworkArray = res.data.networkList

          // res.data.networkList.forEach((obj: any,index:number) => {
          //   let network = []
          //     network.push({
          //       networkName: obj.networks,
          //       withdrawlFee: obj.withdrawFee,
          //       minimumWithdrawal: obj.minwithdrawAmount,
          //       withdrawalAmountMax: obj.maxWithdrawAmount
          //     })
          //     let temp = obj[0]
          //     temp['network'] = network
          //     if (index == obj.length - 1) {
          //       this.coinNetworkArray.push(temp)
          //     }
          // });
          // this.getCoinStatus();
          this.service.hideSpinner();
        } else {
          this.service.hideSpinner();
        }
      },
      (error) => {
        this.service.hideSpinner();
      }
    );
  }
  updateMnageCoins() {
    this.service.showSpinner();
    let data = {
      coinName: this.editedCoinName,
      confermation: 0,
      isVisible: this.manageCoinForm.value.isVisible,
      p2pStatus: this.manageCoinForm.value.p2pStatus,
      istransfer: this.manageCoinForm.value.istransfer,
    };
    this.service
      .post("wallet/admin/set-coin-status?coin=" + this.editedCoinName, data)
      .subscribe(
        (res: any) => {
          if (res.status == 200) {
            this.particularData = res.data;
            this.service.hideSpinner();
            this.service.toasterSucc(res.message);
          }
          else {
            this.service.hideSpinner();
          }
        },
        (error) => {
          this.service.hideSpinner();
        }
      );
  }
  validateFormCold() {
    if (this.maxWithdrawAmount || this.minwithdrawAmount || this.withdrawFee || this.minDeposit) {
      return false
    } else {
      return true
    }
  }
  updateCoinvisible() {
    this.service.showSpinner()
    let url = `wallet/coin/admin/update-coin-status?coinId=${this.particularData.coinId}&isTransfer=${this.manageCoinForm.value.istransfer ? this.manageCoinForm.value.istransfer : false}&isVisible=${this.manageCoinForm.value.isVisible ? this.manageCoinForm.value.isVisible : false}&p2pStatus=${this.manageCoinForm.value.p2pStatus ? this.manageCoinForm.value.p2pStatus : false}`
    // let url = `wallet/coin/set-Coin-Visible?coinName=${this.editedCoinName}&isVisible=${this.manageCoinForm.value.isVisible ? this.manageCoinForm.value.isVisible : false}&iswithdraw=${this.manageCoinForm.value.isWithdrawl ? this.manageCoinForm.value.isWithdrawl : false}&remark=${this.manageCoinForm.value.remark ? this.manageCoinForm.value.remark : false}&isDeposit=${this.manageCoinForm.value.isDeposite ? this.manageCoinForm.value.isDeposite : false}&isTransfer=${this.manageCoinForm.value.istransfer ? this.manageCoinForm.value.istransfer : false}`
    this.service
      .putApi(url, {})
      .subscribe(
        (res: any) => {
          if (res.status == 200) {
            // this.particularData = res.data;
            this.service.hideSpinner();
            this.service.toasterSucc(res.message);
            this.editStaff(this.particularData.coinShortName)
          }
          else {
            this.service.hideSpinner();
          }
        },
        (error) => {
          this.service.hideSpinner();
        }
      );
  }



  editStaff(element) {
    this.editedCoinName = element;
    console.log(element);

    this.manageCoins = false;
    let data = {
      coinName: "string",
      confermation: 0,
      isVisible: true,
      p2pStatus: true,
      istransfer: true,
    };
    this.service.get("wallet/coin/get-coin-details?coinName=" + element).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.particularData = res.data;
          this.manageCoinForm.patchValue({

            isVisible: this.particularData.isVisible,
            p2pStatus: this.particularData.p2pStatus,
            istransfer: this.particularData.istransfer,
          })
          this.service.hideSpinner();
        }
      },
      (error) => {
        this.service.hideSpinner();
      }
    );
  }

  manageCoinsss() {
    this.manageTrading = true;
    this.getCoinWalletList();
  }

  editStafff(baseCoin, executableCoin, visible) {
    this.manageTrading = false;
    this.baseCoinn = baseCoin;
    this.executableCoinn = executableCoin;
    this.visiblen = visible;
    let data = {
      baseCoin: this.manageCoinPair.value.baseCoin,
      executableCoin: this.manageCoinPair.value.executableCoin,
      visible: this.manageCoinPair.value.visible,
    };
  }

  getCoinStatus() {
    this.service.showSpinner();
    this.coinListArr.forEach((element) => {
      this.service
        .get("wallet/coin/get-coin-details?coinName=" + element)
        .subscribe(
          (res: any) => {
            if (res.status == 200) {
              this.loopdata.push(res.data);
              this.service.hideSpinner();
            }
          },
          (error) => {
            this.service.hideSpinner();
          }
        );
    });
  }

  manageCoinss() {
    this.manageCoins = true;
    this.getCoinWalletList()
  }
  defaults() {
    this.currTab = "Fee";
    this.getCoinList();
  }
  todate() {
    this.twoDate = new Date(this.calender.todate);
    this.twoDate = this.twoDate.getTime();
  }
  formdate() {
    this.fromDate = new Date(this.calender.formdate);
    this.fromDate = this.fromDate.getTime();
  }
  /** to switch between tabs */
  selectTab(tab) {
    this.currTab = tab;
    if (this.currTab == "Fee") {
      this.getCoinList();
    } else if (this.currTab == "Tacker") {
      this.getCoinList();
    } else if (this.currTab == "Feer") {
      this.getCoinList();
    }
  }

  coinfunction(coin) {
    this.currencycoin = coin;
  }

  demo(val) {
    this.optionFee = val;
    this.show = false;
  }
  depositMinimum: any = []
  /**to get coin list */
  getCoinList() {
    this.service.showSpinner();
    this.service.get("wallet/coin/admin/get-full-coin-list").subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.feecoinArry = res["data"];
          this.minimumFeesArray = res["data"];
          this.depositMinimum = res['data']
          this.Hggcoin = res["data"][5].privilegePrice;
          this.feecoinArry.forEach((obj) => {
            let pushobj = {
              coinShortName: obj.coinShortName,
              withdrawlfee: obj.withdrawlFee,
            };
          });
          this.depositMinimum.forEach((obj) => {
            let pushobj = {
              coinShortName: obj.coinShortName,
              depositeAmount: obj.depositeAmount
            }
          })
          this.minimumFeesArray.forEach((obj) => {
            let pushobj = {
              coinShortName: obj.coinShortName,
              withdrawalAmount: obj.withdrawalAmount,
              withdrawalAmountMax: obj.withdrawalAmountMax
            };
          });
        } else {
          this.service.toasterErr(res["message"]);
          this.service.hideSpinner();
        }
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }

  minimumFeesArray: any = [];
  updateminimumfeeapi(coinShortName, withdrawalAmount, withdrawalAmountMax) {
    // if (!this.numRegxForDot.test(withdrawalAmount)) {
    //   this.service.toasterErr("Enter valid input.");
    // } else if (withdrawalAmount > 100) {
    //   this.service.toasterErr("Coin fee can't be greater than 100%");
    //   return;
    // }
    // else if (!this.numRegxForDot.test(withdrawalAmountMax)) {
    //   this.service.toasterErr("Enter valid input.");
    // }
    // else if (withdrawalAmountMax > 100) {
    //   this.service.toasterErr("Coin fee can't be greater than 100%");
    //   return;
    // }
    // else {
    let url = `wallet/admin/fee-management/set-minimum-withdrawal-amount?coinName=${coinShortName}&withdrawalAmount=${withdrawalAmount}&withdrawalAmountMax=${withdrawalAmountMax}`
    this.service.showSpinner();
    this.service.get(url)
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.minimumFeesArray = res["data"];
            this.service.toasterSucc('Amount Set Successfully');
            this.getCoinList();
          } else {
            this.service.toasterErr("rghfg");
            this.service.hideSpinner();
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    // }
  }

  updatefeeapi(coinShortName, withdraw) {
    if (withdraw == "") {
      this.service.toasterErr("Enter valid input.");
      return;
    }
    if (!this.numRegxForDot.test(withdraw)) {
      this.service.toasterErr("Enter valid input.");
    } else if (withdraw > 100) {
      this.service.toasterErr("Coin fee can't be greater than 100%");
      return;
    } else {
      this.service.showSpinner();
      this.service
        .get(
          "wallet/admin/fee-management/set-withdrawal-fee?coinName=" +
          coinShortName +
          "&withdrawalFee=" +
          withdraw
        )
        .subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              this.feecoinArry = res["data"];
              this.service.toasterSucc(res["message"]);
              this.getCoinList();
            } else {
              this.service.toasterErr("rghfg");
              this.service.hideSpinner();
            }
          },
          (err) => {
            this.service.hideSpinner();
          }
        );
    }
  }
  updateMinimumDeposit(coinShortName, depositeAmount) {
    let url = `wallet/admin/fee-management/set-minimum-deposit-amount?coinName=${coinShortName}&depositeAmount=${depositeAmount}`
    this.service.get(url).subscribe((res) => {
      this.service.hideSpinner();
      if (res["status"] == 200) {
        this.depositMinimum = res["data"];
        this.service.toasterSucc("Minimum deposit amount updated successfully");
        this.getCoinList();
      } else {
        this.service.toasterErr(res['message']);
        this.service.hideSpinner();
      }
    },
      (err) => {
        this.service.hideSpinner();
      }
    );

  }
  updateTacker(coin, tacker, macker) {
    if (tacker == "" || macker == "") {
      this.service.toasterErr("Enter valid input.");
      return;
    }
    if (!this.numRegxForDot.test(tacker)) {
      this.service.toasterErr("Enter valid input.");
    } else if (!this.numRegxForDot.test(macker)) {
      this.service.toasterErr("Enter valid input.");
      return;
    } else {
      let data = {
        coinName: coin,
        makerFee: macker,
        takerFee: tacker,
      };
      this.service.showSpinner();
      this.service
        .post("wallet/admin/fee-management/set-taker-maker-fee", data)
        .subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              this.service.toasterSucc(res["message"]);
            } else {
              this.service.toasterErr(res["message"]);
              this.service.hideSpinner();
            }
          },
          (err) => {
            this.service.hideSpinner();
          }
        );
    }
  }

  coinId: any
  openModal(coinId) {
    this.coinId = coinId
    // alert(coinId)
    $('#deleteModal').modal('show')
  }
  deleteFunction() {
    var url = 'wallet/coin/coin-delete?coinId=' + (this.coinId);
    this.service.showSpinner();
    this.service.delete(url).subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        $('#deleteModal').modal('hide')
        this.service.toasterSucc('Coin Deleted Successfully');
        this.getCoinWalletList();
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  // edit category
  editBody(coinShortName) {
    this.router.navigate(['/edit-coin'], { queryParams: { coinName: coinShortName } })
  }

  coinNetworkArray: any = []

  selectedObject: any
  getValue: any = {}
  minDeposit: any
  minwithdrawAmount: any
  maxWithdrawAmount: any
  withdrawFee: any

  openModale(item) {
    this.selectedObject = item
    console.log(this.selectedObject);

    this.selectedNework = item.networkList[0]
    this.minDeposit = this.selectedNework.minDeposit || 0
    this.minwithdrawAmount = this.selectedNework.minwithdrawAmount || 0
    this.maxWithdrawAmount = this.selectedNework.maxWithdrawAmount || 0
    this.withdrawFee = this.selectedNework.withdrawFee || 0
    $('#editCryptoModal').modal({
      backdrop: "static"
    })
  }
  openModalSS(item) {
    this.getValue = item
    $('#withdrwalLimitSetDaily').modal({
      backdrop: "static"
    })
  }
  selectedObjectFiat: any = {}
  openFiatModal(item) {
    this.selectedObjectFiat = item
    $('#editFiatModal').modal({
      backdrop: "static"
    })
  }
  selectedNework: any = {}
  updateNetwork() {
    let url = `wallet/coin/admin/min/max/fee-withdraw-transfer-fee?coinName=${this.selectedObject.coinShortName}&coinType=Crypto&maxWithdraw=${this.maxWithdrawAmount}&minWithdraw=${this.minwithdrawAmount}&network=${this.selectedNework.networks}&withdrawFee=${this.withdrawFee}&minDeposit=${this.minDeposit}`
    // let data = {
    //   "maxWithdrawAmount": this.selectedNework.maxWithdrawAmount,
    //   "minwithdrawAmount": this.selectedNework.minwithdrawAmount,
    //   "withdrawFee": this.selectedNework.withdrawFee
    // };

    // data = this.service.removeEmptyKey(data)
    let data = {}
    this.service.showSpinner();
    this.service
      .putApi(url, data)
      .subscribe((res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          $('#editCryptoModal').modal('hide')
          this.service.toasterSucc(res["message"]);
          this.ngOnInit()
        } else {
          this.service.toasterErr(res["message"]);
          this.service.hideSpinner();
        }
      },
        (err) => {
          this.service.hideSpinner();
        })
  }
  updateDailyLimitValue() {
    let url = `wallet/coin/set-coin-exceed-amount?coinAmount=${this.getValue.coinExceedAmount}&coinId=${this.getValue.coinId}`
    this.service.showSpinner();
    this.service.putApi(url, {}).subscribe((res) => {
      this.service.hideSpinner();
      if (res["status"] == 200) {
        $('#withdrwalLimitSetDaily').modal('hide')
        this.service.toasterSucc(res["message"]);
      } else {
        this.service.toasterErr(res["message"]);
        this.service.hideSpinner();
      }
    },
      (err) => {
        this.service.hideSpinner();
      })
  }
  updateFiat() {
    let data = {

    };
    let url = `wallet/coin/admin/min/max/fee-withdraw-transfer-fee?coinName=${this.selectedObjectFiat.coinShortName}&coinType=Fiat&depositFee=${this.selectedObjectFiat.depositFee}&maxDeposit=${this.selectedObjectFiat.maxDeposit}&maxWithdraw=${this.selectedObjectFiat.withdrawalAmountMax}&minDeposit=${this.selectedObjectFiat.minDeposit}&minWithdraw=${this.selectedObjectFiat.withdrawalAmount}&withdrawFee=${this.selectedObjectFiat.withdrawlFee}`
    // data = this.service.removeEmptyKey(data)
    this.service.showSpinner();
    this.service
      .putApi(url, data)
      .subscribe((res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          $('#editFiatModal').modal('hide')
          this.service.toasterSucc(res["message"]);
        } else {
          this.service.toasterErr(res["message"]);
          this.service.hideSpinner();
        }
      },
        (err) => {
          this.service.hideSpinner();
        })
  }
}
